import i18next from 'i18next';
import pis_zu from '../../../translations/zu/pis.json';
import pis_en from '../../../translations/en/pis.json';
import consent_zu from '../../../translations/zu/consent.json';
import forms_zu from '../../../translations/zu/forms.json';
import values_zu from '../../../translations/zu/values.json';
import video_zu from '../../../translations/zu/video.json';
import video_en from '../../../translations/en/video.json';
import consent_en from '../../../translations/en/consent.json';
import forms_en from '../../../translations/en/forms.json';
import values_en from '../../../translations/en/values.json';

export const setupI18next = (defaultLanguage = 'en') => {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: defaultLanguage, // language to use
    resources: {
      en: {
        pis: pis_en,
        video: video_en,
        consent: consent_en,
        forms: forms_en,
        values: values_en,
      },
      zu: {
        pis: pis_zu,
        video: video_zu,
        consent: consent_zu,
        forms: forms_zu,
        values: values_zu,
      },
    },
  });
  return i18next;
};
