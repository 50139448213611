import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import participantApi from './participantApi';
import {
  ParticipantState,
  ParticipantLookupResponse,
  ParticipantLookupRejection,
  ConsentVersionResponse,
} from './types';
import { KeyValues, StoreStatus } from '../../core/types/common.types';

// Initial State
const initialState: ParticipantState = {
  errors: null,
  result: null,
  status: StoreStatus.IDLE,
  values: null,
  consentStatus: StoreStatus.IDLE,
  consentResult: null,
  consentErrors: null,
};

// Thunks
export const lookupParticipant = createAsyncThunk<ParticipantLookupResponse, KeyValues, ParticipantLookupRejection>(
  'participant/lookup',
  async (values, { rejectWithValue, dispatch }) => {
    try {
      dispatch(stepForward(values));
      const response = await participantApi.lookupParticipant(values);
      return response.data.studyParticipant;
    } catch (error: any) {
      return rejectWithValue(error.errors);
    }
  },
);

export const getConsentFormVersion = createAsyncThunk(
  'participant/GetConsentFormVersion',
  async (value: undefined, { rejectWithValue }: any) => {
    try {
      const response: ConsentVersionResponse | any = await participantApi.getConsentFormVersion();
      return response.data.consentFormVersion?.consentVersion;
    } catch (error: any) {
      return rejectWithValue(error.errors);
    }
  },
);

// Slice
export const participantSlice = createSlice({
  name: 'participant',
  initialState,
  reducers: {
    resetParticipantResult: (state) => ({
      ...state,
      status: initialState.status,
      error: initialState.errors,
      result: initialState.result,
    }),
    resetParticipantStore: () => ({
      ...initialState,
    }),
    stepForward: (state, action) => ({
      ...state,
      values: { ...state.values, ...action.payload },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(lookupParticipant.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });
    builder.addCase(lookupParticipant.fulfilled.type, (state, action: any) => {
      state.errors = null;
      state.result = action.payload;
      state.status = StoreStatus.IDLE;
    });
    builder.addCase(lookupParticipant.rejected.type, (state, action: any) => {
      state.errors = action.payload;
      state.result = null;
      state.status = StoreStatus.ERROR;
    });
    builder.addCase(getConsentFormVersion.pending.type, (state) => {
      state.consentStatus = StoreStatus.BUSY;
    });
    builder.addCase(getConsentFormVersion.fulfilled.type, (state, action: any) => {
      state.consentErrors = null;
      state.consentResult = action.payload;
      state.consentStatus = StoreStatus.IDLE;
    });
    builder.addCase(getConsentFormVersion.rejected.type, (state, action: any) => {
      state.consentErrors = action.payload;
      state.consentResult = null;
      state.consentStatus = StoreStatus.ERROR;
    });
  },
});

// Action Creators
export const { resetParticipantStore, resetParticipantResult, stepForward } = participantSlice.actions;

export default participantSlice.reducer;
