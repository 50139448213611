import { ChangeEvent } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@omnigenbiodata/ui';

function LangauageToggle() {
  const { i18n } = useTranslation();
  const { trackEvent } = useMatomo();

  const handleChange = (event: ChangeEvent<object>, val: number) => {
    const lang = val === 0 ? 'en' : 'zu';
    i18n.changeLanguage(lang);
    trackEvent({ category: 'language', action: `change to ${lang}` });
  };

  return (
    <Tabs
      value={i18n.language === 'zu' ? 1 : 0}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab label="English" />
      <Tab label="isiZulu" />
    </Tabs>
  );
}

export default LangauageToggle;
