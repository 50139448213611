import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { ButtonRow, FormCheckBox, FormRadioGroup, FormStepper, useTrackedFormik } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import { ENROLMENT_STEPS, MESSAGES, TOTAL_CONSENT_STEPS } from '../../../../core/constants/forms.constant';
import { useAppDispatch } from '../../../../store';
import { stepForward } from '../../../../store/enrolment';

const validationSchema = yup.object({
  healthOptin: yup.string().required(MESSAGES.prefsRequired),
  consent4: yup.boolean().oneOf([true], MESSAGES.consentRequired),
});

function Consent4() {
  const { t } = useTranslation('consent');
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: { consent4: false, healthOptin: '' },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.enrolmentConsent5);
      },
    },
    'enrol-consent4',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={1} />
        </Box>
        <Box mb={8}>
          <Paper elevation={5}>
            <Box p={3}>
              <Typography variant="h4" component="h1" align="center" gutterBottom>
                4. {t('section4.title')}
              </Typography>
              <MobileStepper
                backButton={false}
                nextButton={false}
                variant="dots"
                steps={TOTAL_CONSENT_STEPS}
                position="static"
                activeStep={3}
              />
              <Typography variant="body1" component="span" align="left">
                <ReactMarkdown>{t('section4.body')}</ReactMarkdown>
              </Typography>

              <Box mb={2}>
                <FormCheckBox
                  error={formik.errors.consent4}
                  name="consent4"
                  label={t('confirmation')}
                  onChange={formik.handleChange}
                  touched={formik.touched.consent4}
                  value={formik.values.consent4}
                />
              </Box>
              <Typography paragraph variant="body1" component="p" align="left">
                {t('section4.choice')}
              </Typography>
              <FormRadioGroup
                hideLabel
                error={formik.errors.healthOptin}
                name="healthOptin"
                label="Health Data"
                orText={t('section4.or')}
                options={[
                  {
                    value: 'Y',
                    label: t('section4.option1') as string,
                  },
                  {
                    value: 'N',
                    label: t('section4.option2') as string,
                  },
                ]}
                onChange={formik.handleChange}
                touched={formik.touched.healthOptin}
                value={formik.values.healthOptin}
              />
            </Box>
          </Paper>
        </Box>

        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Consent4;
