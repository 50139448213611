import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../store';
import { resetEnrolment } from '../../../../store/enrolment';
import ROUTES from '../../../../core/constants/routes.constant';
import { resetSample } from '../../../../store/samples';
import { resetParticipantStore } from '../../../../store/participant';

function Logout() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetEnrolment());
    dispatch(resetSample());
    dispatch(resetParticipantStore());
  }, [dispatch]);

  return <Navigate to={ROUTES.root} />;
}

export default Logout;
