import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import * as yup from 'yup';
import { ButtonRow, FormInput, FormStepper, authAPI, useTrackedFormik } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../layout/Inner';
import SignatureBlock from '../../../../components/content/SignatureBlock';

import ROUTES from '../../../../core/constants/routes.constant';
import { ENROLMENT_STEPS, MESSAGES, TOTAL_CONSENT_STEPS } from '../../../../core/constants/forms.constant';
import { useAppDispatch } from '../../../../store';
import { stepForward } from '../../../../store/enrolment';

const validationSchema = yup.object({
  staffMemberFirstName: yup
    .string()
    .required(MESSAGES.firstNameRequired)
    .test('asyncFirstName', MESSAGES.userFirstNameRequired, async (value) => {
      const {
        attributes: { given_name: givenName },
      } = await authAPI.getCurrentUser();
      return givenName === value;
    }),
  staffMemberSurname: yup
    .string()
    .required(MESSAGES.lastNameRequired)
    .test('asyncLastName', MESSAGES.userLastNameRequired, async (value) => {
      const {
        attributes: { family_name: familyName },
      } = await authAPI.getCurrentUser();
      return familyName === value;
    }),
  staffSignature: yup.string().required(MESSAGES.staffSigRequired),
});

function Consent12() {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        staffMemberFirstName: '',
        staffMemberSurname: '',
        staffSignature: '',
        staffSignatureTime: moment().toISOString(),
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.enrolmentDetails1);
      },
    },
    'enrol-staff-signature',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={1} />
        </Box>
        <Typography variant="h4" component="h1" align="center">
          9. Signatures
        </Typography>
        <Typography variant="h5" component="h2" align="center" gutterBottom>
          Discover Me research team member signature
        </Typography>
        <MobileStepper
          backButton={false}
          nextButton={false}
          variant="dots"
          steps={TOTAL_CONSENT_STEPS}
          position="static"
          activeStep={8}
        />

        <Box mb={8}>
          <FormInput
            error={formik.errors.staffMemberFirstName}
            name="staffMemberFirstName"
            label="First name of Discover Me research team member taking consent"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.staffMemberFirstName}
            value={formik.values.staffMemberFirstName}
          />
          <FormInput
            error={formik.errors.staffMemberSurname}
            name="staffMemberSurname"
            label="Surname of Discover Me research team member taking consent"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.staffMemberSurname}
            value={formik.values.staffMemberSurname}
          />
        </Box>
        <Box mb={4}>
          <Grid container spacing={2}>
            <Grid item sm={2} />
            <Grid item sm={8}>
              <SignatureBlock
                error={formik.errors.staffSignature}
                name="staffSignature"
                label="Please sign below"
                value={formik.values.staffSignature}
                touched={formik.touched.staffSignature}
                setValue={formik.setFieldValue}
              />
            </Grid>
          </Grid>
        </Box>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Consent12;
