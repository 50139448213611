import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormCheckBox, FormStepper, useTrackedFormik } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import { ENROLMENT_STEPS, MESSAGES, TOTAL_CONSENT_STEPS } from '../../../../core/constants/forms.constant';
import { useAppDispatch } from '../../../../store';
import { stepForward } from '../../../../store/enrolment';

import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

const validationSchema = yup.object({
  consent5: yup.boolean().oneOf([true], MESSAGES.consentRequired),
});

function Consent5() {
  const { t } = useTranslation('consent');
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: { consent5: false },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.enrolmentConsent6);
      },
    },
    'enrol-consent5',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={1} />
        </Box>
        <Box mb={8}>
          <Paper elevation={5}>
            <Box p={3}>
              <Typography variant="h4" component="h1" align="center" gutterBottom>
                5. {t('section5.title')}
              </Typography>
              <MobileStepper
                backButton={false}
                nextButton={false}
                variant="dots"
                steps={TOTAL_CONSENT_STEPS}
                position="static"
                activeStep={4}
              />
              <Typography variant="body1" component="span" align="left">
                <ReactMarkdown>{t('section5.body')}</ReactMarkdown>
              </Typography>

              <FormCheckBox
                error={formik.errors.consent5}
                name="consent5"
                label={t('confirmation')}
                onChange={formik.handleChange}
                touched={formik.touched.consent5}
                value={formik.values.consent5}
              />
            </Box>
          </Paper>
        </Box>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Consent5;
