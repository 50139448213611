/** @jsxImportSource @emotion/react */
import { useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SignatureCanvas from 'react-signature-canvas';
import { useStyles } from './component.styles';
import { useMatomo } from '@omnigenbiodata/ui';

export interface SignatureBlockProps {
  error?: string;
  label: string;
  name: string;
  setValue: (name: string, value: string) => void;
  touched?: boolean;
  value: string;
}

function SignatureBlock({ error, label, name, value, setValue, touched }: SignatureBlockProps) {
  const classes = useStyles();
  const signCanvasEl = useRef<any>(null);
  const { trackEvent } = useMatomo();

  useEffect(() => {
    if (value && signCanvasEl) {
      signCanvasEl.current?.fromDataURL(value);
    }
  }, [value, signCanvasEl]);

  return (
    <Box pt={2} pb={2}>
      <Typography variant="body1" component="p" align="center" paragraph>
        {label}
      </Typography>
      <Paper variant="outlined" css={[classes.signContainer, touched && error ? classes.error : undefined]}>
        <SignatureCanvas
          ref={signCanvasEl}
          clearOnResize={false}
          canvasProps={{
            style: { width: '100%', height: '100%' },
          }}
          onEnd={() => {
            if (signCanvasEl) {
              trackEvent({ category: name, action: 'sign' });
              setValue(name, signCanvasEl ? signCanvasEl.current.toDataURL('img/png') : '');
            }
          }}
        />
      </Paper>
      {touched && error && (
        <Typography variant="caption" component="p" align="center" css={classes.errorText} paragraph>
          {error}
        </Typography>
      )}
      {value && (
        <Button
          css={classes.clearSig}
          variant="text"
          size="small"
          data-testid={`${name}-clear`}
          onClick={() => {
            if (signCanvasEl) {
              trackEvent({ category: name, action: 'clear' });
              signCanvasEl.current.clear();
              setValue(name, '');
            }
          }}
        >
          Clear Signature
        </Button>
      )}
    </Box>
  );
}

export default SignatureBlock;
