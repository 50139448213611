export const PARTNER_LOGOS = [
  {
    img: '/images/kwazulu-natal-province.png',
    alt: 'Kwazulu-Natal Province',
  },
  {
    img: '/images/kwazulu-uni-logo.png',
    alt: 'University of Kwazulu-Natal',
  },
  { img: '/images/omnigen-logo-2-x.png', alt: 'Omnigen' },
];

export const SUPPORT_EMAIL = 'researchdmsa@omnigenbiodata.co.uk';
export const STUDY_EMAIL = 'discovermesa@ukzn.ac.za';
export const STUDY_TEL = '083 4633 156';
export const STUDY_URL = 'www.discovermestudy.com/dmsa';
export const STUDY_ADDRESS =
  'For Attn: Professor Ayesha Motala, Department of Diabetes and Endocrinology, University of KwaZulu-Natal, 719 Umbilo Road, Room 343, Congella, Durban, 4013';

// This must be updated when any content changes are made to the consent process
// The version is submitted and stored with each enrolment
export const CONSENT_FORM_VERSION = 'v5.1';
export const PIS_VERSION = 'v5.2';
export const PIS_DATE = '06 April 202​​3';
