import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { ButtonRow, FormCheckBox, FormStepper, useTrackedFormik } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import { stepForward } from '../../../../store/enrolment';
import { useAppDispatch } from '../../../../store';
import { ENROLMENT_STEPS, MESSAGES } from '../../../../core/constants/forms.constant';
import ROUTES from '../../../../core/constants/routes.constant';
import ParticipantVideo from '../../../../components/content/ParticipantVideo';

const validationSchema = yup.object({
  confirmVideo: yup.boolean().oneOf([true], MESSAGES.videoRequired),
});

function Video() {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        confirmVideo: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.enrolmentPis);
      },
    },
    'enrol-video',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={0} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Enrolment Video
        </Typography>
        <Typography paragraph variant="body1" align="left">
          The Discover Me South Africa study enrolment video should be viewed by the participant from start to finish at
          least once before proceeding with enrolment.
        </Typography>
        <Typography paragraph variant="body1" align="left">
          If the participant has yet to view the enrolment video prior to starting this enrolment form, for example as
          part of group enrolment, please ask them to watch the video now.
        </Typography>
        <Typography paragraph variant="body1" align="left">
          This video outlines what this study is about, and what happens if they choose to participate in the study.
        </Typography>
        <Typography paragraph variant="body1" align="left">
          The participant can choose to replay this video as many times as they wish.
        </Typography>
        <Box mb={4}>
          <ParticipantVideo title="Enrolment Video" />
        </Box>

        <Box mb={8}>
          <FormCheckBox
            error={formik.errors.confirmVideo}
            name="confirmVideo"
            label="I confirm that the participant has viewed the enrolment video from start to finish at least once."
            onChange={formik.handleChange}
            touched={formik.touched.confirmVideo}
            value={formik.values.confirmVideo}
          />
        </Box>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Video;
