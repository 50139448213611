import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormStepper, useAuth } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import { SAMPLE_STEPS } from '../../../../core/constants/forms.constant';
import { useAppDispatch } from '../../../../store';
import { resetSample } from '../../../../store/samples';

function Success() {
  const dispatch = useAppDispatch();
  const { signOut } = useAuth();

  return (
    <InnerLayout>
      <Box my={3}>
        <FormStepper steps={SAMPLE_STEPS} activeStep={5} />
      </Box>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Samples Submitted
      </Typography>

      <Box mb={2}>
        <Alert severity="success">
          <Typography variant="body1" component="p" gutterBottom>
            <strong>Thank you, sample collection is now complete.</strong>
          </Typography>
        </Alert>
      </Box>
      <ButtonRow
        backLabel="Return to homepage"
        onBack={() => {
          dispatch(resetSample());
          signOut();
        }}
        showForward={false}
        buttonSize="large"
      />
    </InnerLayout>
  );
}

export default Success;
