import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { HOSPTIAL_NUM_PREFIX } from '../../../../../../core/constants/forms.constant';

interface Props {
  ialchid?: string;
}

function NotFoundMessage({ ialchid }: Props) {
  return (
    <Box mb={2}>
      <Alert severity="error">
        <Typography variant="body1" gutterBottom>
          <strong>Participant Not Found</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          No participant has been enrolled with the IALCH number{' '}
          <strong>
            {HOSPTIAL_NUM_PREFIX}
            {ialchid}
          </strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Please check that this number is the same as the one listed on the patient&apos;s{' '}
          <strong>appointment card</strong>.
        </Typography>
        <Typography variant="body1">A participant must be enrolled in the study to provide a sample.</Typography>
      </Alert>
    </Box>
  );
}

export default NotFoundMessage;
