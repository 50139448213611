import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import moment from 'moment';
import { ButtonRow, FormDateTime, FormSelect, FormStepper, useTrackedFormik } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import {
  BLOOD_STATUSES_ALL,
  SAMPLE_STEPS,
  BLOOD_STATUSES_INVALID,
  BLOOD_STATUSES_VALID,
  DATE_VALUE_FORMAT,
  MESSAGES,
} from '../../../../core/constants/forms.constant';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { stepForward } from '../../../../store/samples';
import { formValueSelector } from '../../../../store/samples/selectors';
import { BloodSampleType } from '../../../../core/types/common.types';

const validationSchema = yup.object({
  plasmaBloodStatus: yup.string().required(MESSAGES.plasmaStatusRequired),
  plasmaBloodCollectionDateTime: yup.string().when('plasmaBloodStatus', {
    is: (value) => BLOOD_STATUSES_VALID.includes(value),
    then: yup
      .string()
      .test('isPast', MESSAGES.pastDateRequired, function (dateString) {
        return moment(dateString).isBefore();
      })
      .required(MESSAGES.collectionTimeRequired),
  }),
});

function PlasmaBloodStatus() {
  const dispatch = useAppDispatch();
  const values = useAppSelector(formValueSelector);
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        plasmaBloodStatus: '',
        plasmaBloodCollectionDateTime: moment().format(DATE_VALUE_FORMAT),
        ...values,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (BLOOD_STATUSES_INVALID.includes(values.plasmaBloodStatus)) {
          values.plasmaBloodCollectionDateTime = '';
        }
        dispatch(stepForward(values));
        navigateTo(ROUTES.samplesPlasma2);
      },
    },
    'samples-plasma-status',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={SAMPLE_STEPS} activeStep={2} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Plasma Blood Status
        </Typography>

        <Typography paragraph variant="body1" align="center">
          Please select the status of the sample taken
        </Typography>

        <Box mb={8}>
          <FormSelect
            error={formik.errors.plasmaBloodStatus}
            name="plasmaBloodStatus"
            label="Plasma Sample Status"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={BLOOD_STATUSES_ALL.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder=""
            touched={formik.touched.plasmaBloodStatus}
            value={formik.values.plasmaBloodStatus}
          />
          {BLOOD_STATUSES_VALID.includes(formik.values.plasmaBloodStatus as BloodSampleType) && (
            <FormDateTime
              error={formik.errors.plasmaBloodCollectionDateTime}
              name="plasmaBloodCollectionDateTime"
              label="Collection Time"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              setValue={formik.setFieldValue}
              touched={formik.touched.plasmaBloodCollectionDateTime}
              value={formik.values.plasmaBloodCollectionDateTime}
            />
          )}
        </Box>

        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default PlasmaBloodStatus;
