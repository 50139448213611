import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import enrolmentApi from './enrolmentApi';
import { EnrolmentResponse, EnrolmentState, EnrolmentRejectionResponse } from './types';
import { StoreStatus, KeyValues } from '../../core/types/common.types';

// Initial State
const initialState: EnrolmentState = {
  status: StoreStatus.IDLE,
  values: null,
  result: null,
  errors: null,
};

// Thunks
export const submitApplication = createAsyncThunk<EnrolmentResponse, KeyValues, EnrolmentRejectionResponse>(
  'enrolment/submit',
  async (values, { rejectWithValue }) => {
    try {
      const response = await enrolmentApi.submitApplication(values);
      return response.data.studyParticipantCreate;
    } catch (error: any) {
      return rejectWithValue(error.errors);
    }
  },
);

// Slice
export const enrolmentSlice = createSlice({
  name: 'enrolment',
  initialState,
  reducers: {
    resetEnrolmentResult: (state) => ({
      ...state,
      status: initialState.status,
      errors: initialState.errors,
      result: initialState.result,
    }),
    resetEnrolment: () => ({
      ...initialState,
    }),
    stepForward: (state, action) => ({
      ...state,
      values: { ...state.values, ...action.payload },
      errors: initialState.errors,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(submitApplication.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
      state.errors = null;
    });
    builder.addCase(submitApplication.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.result = action.payload;
      state.errors = null;
      state.values = null;
    });
    builder.addCase(submitApplication.rejected.type, (state, action: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.result = null;
    });
  },
});

// Action Creators
export const { resetEnrolmentResult, stepForward, resetEnrolment } = enrolmentSlice.actions;

export default enrolmentSlice.reducer;
