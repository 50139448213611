import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { ButtonRow, FormSelect, FormDateTime, FormStepper, useTrackedFormik } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';

import ROUTES from '../../../../core/constants/routes.constant';
import {
  BLOOD_STATUSES_INVALID,
  BLOOD_STATUSES_ALL,
  SAMPLE_STEPS,
  BLOOD_STATUSES_VALID,
  DATE_VALUE_FORMAT,
  MESSAGES,
} from '../../../../core/constants/forms.constant';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { stepForward } from '../../../../store/samples';
import { formValueSelector } from '../../../../store/samples/selectors';
import { formValueSelector as participantValueSelector } from '../../../../store/participant/selectors';
import moment from 'moment';
import { BloodSampleType } from '../../../../core/types/common.types';

const validationSchema = yup.object({
  serumBloodStatus: yup.string().required(MESSAGES.serumStatusRequired),
  serumBloodCollectionDateTime: yup.string().when('serumBloodStatus', {
    is: (value) => BLOOD_STATUSES_VALID.includes(value),
    then: yup
      .string()
      .test('isPast', MESSAGES.pastDateRequired, function (dateString) {
        return moment(dateString).isBefore();
      })
      .required(MESSAGES.collectionTimeRequired),
  }),
});

const SerumStatus = () => {
  const dispatch = useAppDispatch();
  const participantValues = useAppSelector(participantValueSelector);
  const values = useAppSelector(formValueSelector);

  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        ...participantValues,
        serumBloodStatus: '',
        serumBloodCollectionDateTime: moment().format(DATE_VALUE_FORMAT),
        ...values,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (BLOOD_STATUSES_INVALID.includes(values.serumBloodStatus)) {
          values.serumBloodCollectionDateTime = '';
        }
        dispatch(stepForward(values));
        navigateTo(ROUTES.samplesSerum2);
      },
    },
    'samples-serum-status',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={SAMPLE_STEPS} activeStep={0} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Serum Blood Status
        </Typography>
        <Typography paragraph variant="body1" align="center">
          Please select the status of the sample taken
        </Typography>
        <Box mb={8}>
          <FormSelect
            error={formik.errors.serumBloodStatus}
            name="serumBloodStatus"
            label="Serum Sample Status"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={BLOOD_STATUSES_ALL.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder=""
            touched={formik.touched.serumBloodStatus}
            value={formik.values.serumBloodStatus}
          />
          {BLOOD_STATUSES_VALID.includes(formik.values.serumBloodStatus as BloodSampleType) && (
            <FormDateTime
              error={formik.errors.serumBloodCollectionDateTime}
              name="serumBloodCollectionDateTime"
              label="Collection Time"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              setValue={formik.setFieldValue}
              touched={formik.touched.serumBloodCollectionDateTime}
              value={formik.values.serumBloodCollectionDateTime}
            />
          )}
        </Box>

        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
};

export default SerumStatus;
