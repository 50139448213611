const BASE = `/`;
const AUTH_BASE = `${BASE}auth/`;
const ENROLMENT_BASE = `${BASE}enrol/`;
const SAMPLE_BASE = `${BASE}sample/`;
const VIDEO_BASE = `${BASE}video/`;
const INFORMATION_BASE = `${BASE}information/`;

export const ROUTES = {
  auth: AUTH_BASE,
  authMultiFactor: `${AUTH_BASE}mfa`,
  authNewPassword: `${AUTH_BASE}newpassword`,
  authResetPassword: `${AUTH_BASE}resetpassword`,
  authSubmitResetPassword: `${AUTH_BASE}submitresetpassword`,
  authSignOut: `${AUTH_BASE}logout`,
  enrolment: `${ENROLMENT_BASE}`,
  enrolmentDepartment: `${ENROLMENT_BASE}department`,
  enrolmentLanguage: `${ENROLMENT_BASE}language`,
  enrolmentEligibility: `${ENROLMENT_BASE}eligibility`,
  enrolmentIneligible: `${ENROLMENT_BASE}ineligible`,
  enrolmentVideo: `${ENROLMENT_BASE}video`,
  enrolmentPis: `${ENROLMENT_BASE}pis`,
  enrolmentConsent: `${ENROLMENT_BASE}consent`,
  enrolmentConsent1: `${ENROLMENT_BASE}consent1`,
  enrolmentConsent2: `${ENROLMENT_BASE}consent2`,
  enrolmentConsent3: `${ENROLMENT_BASE}consent3`,
  enrolmentConsent4: `${ENROLMENT_BASE}consent4`,
  enrolmentConsent5: `${ENROLMENT_BASE}consent5`,
  enrolmentConsent6: `${ENROLMENT_BASE}consent6`,
  enrolmentConsent7: `${ENROLMENT_BASE}consent7`,
  enrolmentConsent8: `${ENROLMENT_BASE}consent8`,
  enrolmentConsent9: `${ENROLMENT_BASE}participant-signature`,
  enrolmentConsent10: `${ENROLMENT_BASE}witness-signature`,
  enrolmentConsent11: `${ENROLMENT_BASE}researcher-confirm`,
  enrolmentConsent12: `${ENROLMENT_BASE}researcher-signature`,
  enrolmentDetails1: `${ENROLMENT_BASE}participant-details-1`,
  enrolmentDetails2: `${ENROLMENT_BASE}participant-details-2`,
  enrolmentReview: `${ENROLMENT_BASE}review`,
  enrolmentSuccess: `${ENROLMENT_BASE}success`,
  information: INFORMATION_BASE,
  root: BASE,
  samples: SAMPLE_BASE,
  samplesResult: `${SAMPLE_BASE}:ialchid`,
  samplesSerum1: `${SAMPLE_BASE}serum1`,
  samplesSerum2: `${SAMPLE_BASE}serum2`,
  samplesSerum3: `${SAMPLE_BASE}serum3`,
  samplesWholeBlood1: `${SAMPLE_BASE}whole-blood-1`,
  samplesWholeBlood2: `${SAMPLE_BASE}whole-blood-2`,
  samplesPlasma1: `${SAMPLE_BASE}plasma-1`,
  samplesPlasma2: `${SAMPLE_BASE}plasma-2`,
  samplesReview: `${SAMPLE_BASE}review`,
  samplesConfirm: `${SAMPLE_BASE}confirm`,
  samplesParticipantSignature: `${SAMPLE_BASE}participant`,
  samplesWitnessSignature: `${SAMPLE_BASE}witness`,
  samplesStaffMemberSignature: `${SAMPLE_BASE}staff`,
  samplesSuccess: `${SAMPLE_BASE}success`,
  video: VIDEO_BASE,
};

export default ROUTES;
