import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { ButtonRow, FormStepper, useTrackedFormik } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import { ENROLMENT_STEPS } from '../../../../core/constants/forms.constant';

function Consent() {
  const { t } = useTranslation('consent');
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {},
      onSubmit: () => {
        navigateTo(ROUTES.enrolmentConsent1);
      },
    },
    'enrol-consent',
  );

  return (
    <InnerLayout>
      <Box my={3}>
        <FormStepper steps={ENROLMENT_STEPS} activeStep={1} />
      </Box>
      <Box mb={8}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Consent
        </Typography>

        <Typography paragraph variant="body1" align="left">
          Participants should read the Discover Me South Africa consent form, or have it read to them, in full.
        </Typography>

        <Paper elevation={5}>
          <Box p={3}>
            <Typography paragraph variant="body1" align="left">
              <strong>{t('section0.title')}</strong>
            </Typography>
            <Typography paragraph variant="body1" component="div">
              <ReactMarkdown>{t('section0.body')}</ReactMarkdown>
            </Typography>
          </Box>
        </Paper>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Consent;
