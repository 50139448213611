import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { PIS_DATE, PIS_VERSION } from '../../../core/constants/content.constant';

interface ParticipantInfoProps {
  address?: string;
  email?: string;
  telephone?: string;
  web?: string;
}

function ParticipantInfo({ address = '', email = '', telephone = '', web = '' }: ParticipantInfoProps) {
  const { t } = useTranslation('pis');
  return (
    <Paper elevation={5}>
      <Box p={3}>
        <Typography paragraph variant="h5" align="left" component="h2">
          <strong>{t('title')}</strong>
        </Typography>
        <Typography paragraph variant="h6" align="left" component="h3">
          {t('heading1')}
        </Typography>
        <Typography variant="body1" align="left" component="div">
          <ReactMarkdown>{t('intro')}</ReactMarkdown>
        </Typography>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>1. {t('section1.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section1.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>2. {t('section2.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section2.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>3. {t('section3.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section3.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>4. {t('section4.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section4.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>5. {t('section5.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section5.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>6. {t('section6.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section6.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>7. {t('section7.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section7.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>8. {t('section8.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section8.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>9. {t('section9.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section9.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>10. {t('section10.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section10.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>11. {t('section11.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section11.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>12. {t('section12.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section12.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>13. {t('section13.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section13.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>14. {t('section14.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section14.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>15. {t('section15.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section15.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>16. {t('section16.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section16.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>17. {t('section17.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>
              {t('section17.body', {
                address,
                email,
                telephone,
                web,
              })}
            </ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>18. {t('section18.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section18.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>19. {t('section19.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section19.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>20. {t('section20.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section20.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>21. {t('section21.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>{t('section21.body')}</ReactMarkdown>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" align="left" component="h4">
            <strong>22. {t('section22.title')}</strong>
          </Typography>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>
              {t('section22.body', {
                address,
                email,
                telephone,
                web,
              })}
            </ReactMarkdown>
          </Typography>
        </div>

        <div>
          <Typography variant="body1" align="left" component="div">
            <ReactMarkdown>
              {`Discover Me South Africa | Participant information sheet | ${PIS_VERSION} | ${PIS_DATE}`}
            </ReactMarkdown>
          </Typography>
        </div>
      </Box>
    </Paper>
  );
}

export default ParticipantInfo;
