import { API as AMPLIFY_API } from 'aws-amplify';
import { getParticipant, getConsentVersion } from '../../core/graphql/queries';
import { API as MOCK_AMPLIFY_API } from '../../__mocks__/aws-amplify';
import { ConsentVersionResponse, ParticipantLookupResponse } from './types';

const API = process.env.REACT_APP_USE_MOCKS === 'true' ? MOCK_AMPLIFY_API : AMPLIFY_API;

const participantsAPI = {
  lookupParticipant: async (data: any): Promise<ParticipantLookupResponse | any> => {
    return (await API.graphql({
      query: getParticipant,
      variables: {
        input: {
          hospitalNum: data.ialchID,
        },
      },
    })) as ParticipantLookupResponse;
  },
  getConsentFormVersion: async (): Promise<any> => {
    return (await API.graphql({
      query: getConsentVersion,
    })) as ConsentVersionResponse;
  },
};

export default participantsAPI;
