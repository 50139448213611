import { useEffect } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';

import ROUTES from '../../core/constants/routes.constant';
import { formValueSelector, hasSuccessSelector } from '../../store/enrolment/selectors';
import { useAppDispatch, useAppSelector } from '../../store';
import { CONSENT_FORM_VERSION } from '../../core/constants/content.constant';
import { consentVersionSelector, isConsentVersionBusySelector } from '../../store/participant/selectors';
import { getConsentFormVersion } from '../../store/participant';
import Splash from '../../components/Splash';

function Enrolment() {
  const location = useLocation();
  const navigateTo = useNavigate();
  const enrolmentValues = useAppSelector(formValueSelector);
  const hasSuccess = useAppSelector(hasSuccessSelector);
  const latestVersion = useAppSelector(consentVersionSelector);
  const versionLoading = useAppSelector(isConsentVersionBusySelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getConsentFormVersion());
    if (latestVersion && latestVersion !== CONSENT_FORM_VERSION && !localStorage.getItem('hasReloaded')) {
      /* stops indefinite reloading if backend version remains mismatched */
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    } else if (latestVersion && localStorage.getItem('hasReloaded')) {
      localStorage.removeItem('hasReloaded');
    }
  }, [latestVersion, dispatch]);

  useEffect(() => {
    if (
      location.pathname !== ROUTES.enrolment &&
      location.pathname !== ROUTES.enrolmentSuccess &&
      Object.values(enrolmentValues).length === 0 &&
      !hasSuccess
    ) {
      navigateTo(ROUTES.enrolment); // redirect back to start if no questions answered
    }
  }, [navigateTo, location, enrolmentValues, hasSuccess]);

  return versionLoading || !latestVersion ? <Splash /> : <Outlet />;
}

export default Enrolment;
