import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import * as yup from 'yup';
import { ButtonRow, FormInput, useTrackedFormik } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import { useAppDispatch } from '../../../../store';
import { lookupParticipant } from '../../../../store/participant';
import ROUTES from '../../../../core/constants/routes.constant';
import { MESSAGES, HOSPTIAL_NUM_PREFIX } from '../../../../core/constants/forms.constant';

const validationSchema = yup.object({
  ialchID: yup
    .string()
    .required(MESSAGES.idRequired)
    .matches(/^[0-9]+$/, MESSAGES.hospitalNumFormatError)
    .min(8, MESSAGES.hospitalNumLengthError)
    .max(8, MESSAGES.hospitalNumLengthError),
});

function ParticipantLookup() {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();

  const formik = useTrackedFormik(
    {
      initialValues: {
        ialchID: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(lookupParticipant(values));
        navigateTo(ROUTES.samplesResult.replace(':ialchid', values.ialchID));
      },
    },
    'samples-participant-lookup',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h4" component="h1" align="center" paragraph>
          Sample Collection
        </Typography>
        <Paper elevation={9}>
          <Box p={6}>
            <Typography variant="h5" component="h2" align="center" gutterBottom>
              Participant Lookup
            </Typography>

            <Typography paragraph variant="body1" align="center">
              Enter the IALCH ID number of the participant providing a sample
            </Typography>
            <Box mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={1} md={3} />
                <Grid item xs={10} md={6}>
                  <FormInput
                    error={formik.errors.ialchID}
                    name="ialchID"
                    label="IALCH ID"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter a valid IALCH ID"
                    touched={formik.touched.ialchID}
                    value={formik.values.ialchID}
                    startAdornment={<>{HOSPTIAL_NUM_PREFIX}</>}
                  />
                </Grid>
              </Grid>
            </Box>
            <ButtonRow
              showBack={false}
              justifyContent="center"
              forwardLabel="Lookup Participant"
              forwardColor="primary"
              buttonSize="large"
              forwardIcon={<SearchIcon fontSize="inherit" style={{ fontSize: 30 }} />}
            />
          </Box>
        </Paper>
      </form>
    </InnerLayout>
  );
}

export default ParticipantLookup;
