import { Navigate } from 'react-router-dom';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormInput, Loader, useAuth, useTrackedFormik } from '@omnigenbiodata/ui';
import MainLayout from '../../../../layout/Main';
import { MESSAGES } from '../../../../core/constants/forms.constant';
import ROUTES from '../../../../core/constants/routes.constant';

const validationSchema = yup.object().shape({
  email: yup.string().required(MESSAGES.emailRequired),
});

function ResetPassword() {
  const { forgotPasswordHasError, isBusy, forgotPassword, forgotPasswordEmail } = useAuth();

  const formik = useTrackedFormik(
    {
      initialValues: {
        email: '',
      },
      validationSchema,
      onSubmit: (values: any) => {
        forgotPassword(values.email);
      },
    },
    'auth-reset-password',
  );

  return (
    <>
      {forgotPasswordEmail && <Navigate to={ROUTES.authSubmitResetPassword} />}
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={8}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Forgotten password?
            </Typography>
            <Typography paragraph variant="body1" align="center">
              Please enter your email address below:
            </Typography>
          </Box>
          {forgotPasswordHasError && (
            <Box mb={2}>
              <Alert severity="error">
                <Typography variant="body1" paragraph>
                  <strong>Unexpected Error</strong>
                </Typography>
                <Typography variant="body1">Your password could not be reset.</Typography>
              </Alert>
            </Box>
          )}
          <Box mb={8}>
            <FormInput
              error={formik.errors.email as string}
              name="email"
              label="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.email as boolean}
              value={formik.values.email}
            />
          </Box>
          <ButtonRow forwardLabel="Confirm" forwardColor="primary" buttonSize="large" />
        </form>
      </MainLayout>
      <Loader isVisible={isBusy} label="Checking" />
    </>
  );
}

export default ResetPassword;
