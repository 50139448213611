import { API } from 'aws-amplify';
import moment from 'moment';
import { CONSENT_FORM_VERSION, PIS_VERSION } from '../../core/constants/content.constant';
import { studyParticipantCreate } from '../../core/graphql/mutations';
import { EnrolmentResponse } from './types';

const enrolmentAPI = {
  submitApplication: async (data: any): Promise<EnrolmentResponse | any> => {
    return (await API.graphql({
      query: studyParticipantCreate,
      variables: {
        input: {
          region: 'SA',
          orgId: 1,
          hospitalNum: data.ialch,
          nationalID: data.nationalID,
          email: data.email,
          mobile: data.mobile,
          landline: data.landline,
          firstName: data.firstName,
          surname: data.surname,
          dob: data.dob,
          postcode: data.postcode,
          languagePreference: data.languagePreference,
          sex: data.sex,
          address: data.address,
          populationGroup: data.populationGroup,
          selfDefinedEthnicity: data.selfDefinedEthnicity,
          enrolment: {
            departmentofEnrolment: data.departmentOfEnrolment,
            eligibility1: true,
            eligibility2: true,
            enrolmentVideo: true,
            pis: true,
            consent1: true,
            consent2: true,
            consent3: true,
            consent4: true,
            consent5: true,
            consent6: true,
            consent7: true,
            consent8: true,
            consentFormVersion: CONSENT_FORM_VERSION,
            PISVersion: PIS_VERSION,
            health: data.healthOptin === 'Y' ? true : false,
            staffMemberFirstName: data.staffMemberFirstName,
            staffMemberSurname: data.staffMemberSurname,
            staffSignature: data.staffSignature,
            staffSignatureTime: data.staffSignatureTime,
            participantFingerprintFilename:
              data.participantFingerprintFilename !== '' ? data.participantFingerprintFilename : undefined,
            participantSignature: data.participantSignature,
            witnessFirstName: data.witnessFirstName,
            witnessSurname: data.witnessSurname,
            witnessSignature: data.witnessSignature,
            enrolmentTime: moment().toISOString(),
          },
        },
      },
    })) as EnrolmentResponse;
  },
};

export default enrolmentAPI;
