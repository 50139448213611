import COGNITO_CONFIG from './cognito.constant';
import APPSYNC_CONFIG from './appsync.constant';
import S3_CONFIG from './s3.constant';

export const AWS_CONFIG = {
  region: COGNITO_CONFIG.region,
  aws_cognito_region: COGNITO_CONFIG.region,
  userPoolId: COGNITO_CONFIG.user_pool_id,
  identityPoolId: COGNITO_CONFIG.identity_pool_id,
  userPoolWebClientId: COGNITO_CONFIG.app_client_id,
  authenticationFlowType: COGNITO_CONFIG.auth_flow,
  aws_appsync_graphqlEndpoint: APPSYNC_CONFIG.url,
  aws_appsync_region: APPSYNC_CONFIG.region,
  aws_appsync_authenticationType: APPSYNC_CONFIG.authenticationType,
  Storage: {
    AWSS3: {
      bucket: S3_CONFIG.bucket,
      region: COGNITO_CONFIG.region,
    },
  },
};

export default AWS_CONFIG;
