import { Storage } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { Buffer } from 'buffer';
import { v4 as uuidv4 } from 'uuid';

const uploadAPI = {
  uploadImage: async (image: string): Promise<string | any> => {
    const { identityId } = await Auth.currentCredentials();

    const base64Data: Buffer = Buffer.from(image.replace(/^data:image\/\w+;base64,/, ''), 'base64');
    const filename = `${uuidv4()}.jpg`;

    await Storage.put(filename, base64Data, {
      level: 'private', // this will save to {bucket}/private/{identityid}/{filename}
      contentType: 'image/jpg',
      contentEncoding: 'base64',
    });
    return `private/${identityId}/${filename}`;
  },
};

export default uploadAPI;
