export const studyParticipantCreate = `
mutation studyParticipantCreate($input: studyParticipantCreateInput!) {
    studyParticipantCreate(input: $input) {
        participant {
            id
        }
    }
}
`;

export const addSampleBarcode = `
mutation studyParticipantUpdate($input: studyParticipantUpdateInput!) {
    studyParticipantUpdate(input: $input) {
        participant {
            id
        }
    }
}

`;
