import { ReactNode, ReactFragment, ReactPortal, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Fade from '@mui/material/Fade';
import ROUTES from '../../core/constants/routes.constant';
import { PageWrapper, PartnerStrip, DMSALogo, useAuth, useMatomo, theme, Hidden } from '@omnigenbiodata/ui';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './component.styles';
import { PARTNER_LOGOS } from '../../core/constants/content.constant';
import ANIMATIONS from '../../core/constants/animations.constant';

interface InnerLayoutProps {
  children: boolean | ReactNode | ReactFragment | ReactPortal;
}

function InnerLayout({ children }: InnerLayoutProps) {
  const { classes } = useStyles();
  const { signOut } = useAuth();
  const location = useLocation();
  const navigateTo = useNavigate();
  const { trackEvent } = useMatomo();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const isEnrolment = location.pathname.includes(ROUTES.enrolment);
  const isSamples = location.pathname.includes(ROUTES.samples);

  const handleClose = () => {
    trackEvent({ category: 'dialog', action: 'close' });
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    signOut();
    setIsModalOpen(false);
    trackEvent({
      category: 'dialog',
      action: `click end ${isSamples ? 'collection' : 'enrolment'}`,
    });
    navigateTo(isSamples ? ROUTES.samples : ROUTES.enrolment);
  };

  const handleOpen = () => {
    trackEvent({ category: 'dialog', action: 'open' });
    setIsModalOpen(true);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <PageWrapper>
        <div className={classes.header}>
          <div className={classes.headerContent}>
            <DMSALogo size={isMobile ? 'responsive' : 'xs'} />
          </div>
          <Hidden smDown>
            <div className={classes.headerActions}>
              {isEnrolment && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={handleOpen}
                  data-testid="end-enrolment"
                >
                  <KeyboardReturnIcon style={{ marginRight: 10 }} /> End Enrolment
                </Button>
              )}
              {isSamples && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={handleOpen}
                  data-testid="end-collection"
                >
                  <KeyboardReturnIcon style={{ marginRight: 10 }} /> End Collection
                </Button>
              )}
              {location.pathname.includes(ROUTES.video) && (
                <Button variant="outlined" color="primary" size="medium" component={Link} to="/">
                  <CloseIcon />
                </Button>
              )}
              {location.pathname.includes(ROUTES.information) && (
                <Button variant="outlined" color="primary" size="medium" component={Link} to="/">
                  <CloseIcon />
                </Button>
              )}
            </div>
          </Hidden>
        </div>
        <Fade in timeout={ANIMATIONS.duration}>
          <div className={classes.content}>{children}</div>
        </Fade>
        <div className={classes.footer}>
          <PartnerStrip logos={PARTNER_LOGOS} />
        </div>
      </PageWrapper>
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="end-dialog-title"
        aria-describedby="end-dialog-description"
        data-testid="end-modal"
      >
        <DialogTitle id="end-dialog-title">
          {isSamples && 'Are you sure you want to end sample collection?'}
          {isEnrolment && 'Are you sure you want to end enrolment?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="end-dialog-description">
            Any progress or details entered so far will not be saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" size="medium" color="primary">
            Cancel
          </Button>

          <Button
            onClick={handleConfirm}
            autoFocus
            variant="contained"
            size="medium"
            color="error"
            data-testid="confirm-button"
          >
            {isSamples ? 'End Collection' : 'End Enrolment'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InnerLayout;
