import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { RiSyringeLine, RiUser3Line, RiFolderInfoLine, RiVideoLine } from 'react-icons/ri';

import { Link } from 'react-router-dom';
import MainLayout from '../../layout/Main';
import ROUTES from '../../core/constants/routes.constant';
import { theme } from '@omnigenbiodata/ui';

function Home() {
  return (
    <MainLayout title="Enrolment">
      <Box mb={2}>
        <Button fullWidth color="primary" size="xl" variant="contained" component={Link} to={ROUTES.enrolment}>
          <RiUser3Line fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
          Enrol a Participant
        </Button>
      </Box>
      <Box mb={2}>
        <Button fullWidth color="secondary" size="xl" variant="contained" component={Link} to={ROUTES.samples}>
          <RiSyringeLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
          Collect Samples
        </Button>
      </Box>
      <Box mb={2}>
        <Button
          fullWidth
          sx={{
            backgroundColor: theme.palette.primary.lighter,
            color: theme.palette.primary.main,
            '&: hover': {
              backgroundColor: theme.palette.primary.light,
            },
          }}
          size="xl"
          variant="contained"
          component={Link}
          to={ROUTES.information}
        >
          <RiFolderInfoLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
          Participant Information
        </Button>
      </Box>
      <Box mb={2}>
        <Button fullWidth size="xl" variant="outlined" component={Link} to={ROUTES.video}>
          <RiVideoLine fontSize="inherit" style={{ marginRight: 10, fontSize: '1.4em' }} />
          Enrolment Video
        </Button>
      </Box>
    </MainLayout>
  );
}

export default Home;
