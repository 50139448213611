import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface ConfirmNameMatchDialogProps {
  isModalOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function ConfirmNameMatchDialog({ isModalOpen, onClose, onConfirm }: ConfirmNameMatchDialogProps) {
  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      aria-labelledby="end-dialog-title"
      aria-describedby="end-dialog-description"
      data-testid="end-modal"
    >
      <DialogTitle id="end-dialog-title">Is this a real participant?</DialogTitle>
      <DialogContent>
        <DialogContentText id="end-dialog-description">
          The name of the participant matches the name of the staff member enrolling the participant, is this correct?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" size="medium" color="primary" data-testid="cancel-modal-button">
          Cancel
        </Button>

        <Button
          onClick={onConfirm}
          autoFocus
          variant="contained"
          size="medium"
          color="primary"
          data-testid="confirm-modal-button"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmNameMatchDialog;
