import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import { theme, MatomoProvider } from '@omnigenbiodata/ui';
import App from './App';
import store from './store';
import { setupAmplify } from './core/utils/amplify';
import { setupI18next } from './core/utils/i18next';
import reportWebVitals from './reportWebVitals';

const config = {
  urlBase: 'https://discovermestudy.matomo.cloud',
  siteId: 7,
  configurations: {
    setSecureCookie: process.env.NODE_ENV !== 'development',
  },
};

setupAmplify();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (theme?.components?.MuiButton) {
  theme.components.MuiButton.variants = [
    {
      props: { size: 'medium' },
      style: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 20,
        paddingLeft: 20,
        fontSize: theme.typography.h3.fontSize,
        [theme.breakpoints.up('md')]: {
          paddingTop: 10,
          paddingBottom: 10,
          paddingRight: 30,
          paddingLeft: 30,
          fontSize: theme.typography.h5.fontSize,
        },
      },
    },
    {
      props: { size: 'large' },
      style: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingRight: 30,
        paddingLeft: 30,
        fontSize: theme.typography.h5.fontSize,
        [theme.breakpoints.up('md')]: {
          paddingTop: 30,
          paddingBottom: 30,
          paddingRight: 40,
          paddingLeft: 40,
          fontSize: theme.typography.h4.fontSize,
        },
      },
    },
    {
      props: { size: 'xl' },
      style: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingRight: 20,
        paddingLeft: 20,
        fontSize: theme.typography.h5.fontSize,
        [theme.breakpoints.up('md')]: {
          paddingTop: 60,
          paddingBottom: 60,
          paddingRight: 50,
          paddingLeft: 50,
          fontSize: theme.typography.h4.fontSize,
        },
      },
    },
  ];
}

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={setupI18next()}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <MatomoProvider config={config}>
          <App />
        </MatomoProvider>
      </ThemeProvider>
    </I18nextProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
