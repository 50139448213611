export const getParticipant = `
query studyParticipant($input: studyParticipantInput!) {
    studyParticipant(input: $input) {
        id
        profile {
            firstName
            surname
            participantRef
            dob
            sex
            languagePreference
        }
        enrolment {
            witnessFirstName
            witnessSurname
            signatureMethod
        }
        sample {
            wholeBloodSampleID
            wholeBloodStatus
            plasmaSampleID
            plasmaStatus
            serumSampleID 
            serumStatus
        }
    }
}
`;

export const getConsentVersion = `
query consentFormVersionQuery {
    consentFormVersion {
        consentVersion
    }
}
`;
