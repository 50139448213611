import { API } from 'aws-amplify';
import moment from 'moment';
import { addSampleBarcode } from '../../core/graphql/mutations';
import { SampleSubmitResponse } from './types';

const samplesAPI = {
  submitSamples: async (data: any): Promise<SampleSubmitResponse | any> => {
    return (await API.graphql({
      query: addSampleBarcode,
      variables: {
        input: {
          hospitalNum: data?.ialchID,
          sample: {
            serumStatus: data.serumBloodStatus,
            serumSampleID: data.serumBloodBarcode,
            serumCollectionTime: data.serumBloodCollectionDateTime
              ? moment(data.serumBloodCollectionDateTime).toISOString()
              : undefined,
            plasmaStatus: data.plasmaBloodStatus,
            plasmaSampleID: data.plasmaBloodBarcode,
            plasmaCollectionTime: data.plasmaBloodCollectionDateTime
              ? moment(data.plasmaBloodCollectionDateTime).toISOString()
              : undefined,
            wholeBloodStatus: data.wholeBloodStatus,
            wholeBloodSampleID: data.wholeBloodBarcode,
            wholeBloodCollectionTime: data.wholeBloodCollectionDateTime
              ? moment(data.wholeBloodCollectionDateTime).toISOString()
              : undefined,
            sampleStaffMemberFirstName: data.staffMemberFirstName,
            sampleStaffMemberSurname: data.staffMemberSurname,
            sampleStaffMemberSignature: data.staffMemberSignature,
            sampleParticipantSignature: data.participantSignature,
            sampleWitnessSignature: data.witnessSignature,
            sampleSignatureAligned: true,
          },
        },
      },
    })) as SampleSubmitResponse;
  },
};

export default samplesAPI;
