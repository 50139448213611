const COGNITO_CONFIG = {
  region: process.env.REACT_APP_COGNITO_REGION,
  user_pool_id: process.env.REACT_APP_STAFF_COGNITO_USER_POOL_ID,
  app_client_id: process.env.REACT_APP_ENROLMENT_COGNITO_APP_CLIENT_ID,
  identity_pool_id: process.env.REACT_APP_STAFF_COGNITO_IDENTITY_POOL_ID,
  auth_flow: process.env.REACT_APP_COGNITO_AUTH_FLOW,
};

export const AUTH_CHALLENGES = {
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
  MFA_SETUP: 'MFA_SETUP',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  NEW_PASSWORD: 'NEW_PASSWORD',
};

export const MFA_ISSUER =
  process.env.REACT_APP_ENV === 'prod' ? `DMSA_STAFF` : `DMSA_STAFF_${process.env.REACT_APP_ENV?.toUpperCase()}`;

export default COGNITO_CONFIG;
