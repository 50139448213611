import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';

export const hasErrorSelector = (state: RootState): boolean => (state.uploads.errors ? true : false);

export const errorMessagesSelector = (state: RootState): string[] => state.uploads.errors || [];

export const hasSuccessSelector = (state: RootState): boolean => (state.uploads.result ? true : false);

export const filenameSelector = (state: RootState): string | null => state.uploads.result;

export const formValueSelector = (state: RootState): any => state.uploads.values || {};

export const isBusySelector = (state: RootState): boolean => state.uploads.status === StoreStatus.BUSY;
