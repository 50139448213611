import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

function ExistsMessage() {
  return (
    <Box mb={2}>
      <Alert severity="info">
        <Typography variant="body1" gutterBottom>
          <strong>Participant Found</strong>
        </Typography>
        <Typography variant="body1">Sample collection has already been completed for this participant.</Typography>
      </Alert>
    </Box>
  );
}

export default ExistsMessage;
