import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { RiLockPasswordLine } from 'react-icons/ri';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormInput, Loader, useTrackedFormik } from '@omnigenbiodata/ui';
import MainLayout from '../../../../layout/Main';
import { useAuth } from '@omnigenbiodata/ui';

import { MESSAGES, PASSWORD_REGEX } from '../../../../core/constants/forms.constant';

const validationSchema = yup.object().shape({
  firstName: yup.string().required(MESSAGES.firstNameRequired),
  lastName: yup.string().required(MESSAGES.lastNameRequired),
  password: yup.string().required(MESSAGES.passwordRequired).matches(PASSWORD_REGEX, MESSAGES.passwordFormat),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], MESSAGES.passwordNoMatch)
    .required(MESSAGES.passwordConfirmRequired),
});

function NewPassword() {
  const { hasError, isBusy, completeNewPassword } = useAuth();

  const formik = useTrackedFormik(
    {
      initialValues: {
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
      },
      validationSchema,
      onSubmit: (values: any) => {
        completeNewPassword(values);
      },
    },
    'auth-new-password',
  );

  return (
    <>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={8}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              New Password Required
            </Typography>
            <Typography paragraph variant="body1" align="center">
              Please set up your new details below
            </Typography>
          </Box>
          {hasError && (
            <Box mb={2}>
              <Alert severity="error">
                <Typography variant="body1" paragraph>
                  <strong>Registration Failed</strong>
                </Typography>
                <Typography variant="body1">Your details could not be set. Please try again.</Typography>
              </Alert>
            </Box>
          )}
          <Box mb={8}>
            <FormInput
              error={formik.errors.firstName as string}
              name="firstName"
              label="First Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.firstName as boolean}
              value={formik.values.firstName}
            />
            <FormInput
              error={formik.errors.lastName as string}
              name="lastName"
              label="Last Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.lastName as boolean}
              value={formik.values.lastName}
            />
            <FormInput
              error={formik.errors.password as string}
              name="password"
              label="New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.password as boolean}
              value={formik.values.password}
              type="password"
              startAdornment={<RiLockPasswordLine />}
            />
            <FormInput
              error={formik.errors.confirmPassword as string}
              name="confirmPassword"
              label="Confirm New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.confirmPassword as boolean}
              value={formik.values.confirmPassword}
              type="password"
              startAdornment={<RiLockPasswordLine />}
            />
          </Box>
          <ButtonRow forwardLabel="Confirm" forwardColor="primary" buttonSize="large" />
        </form>
      </MainLayout>
      <Loader isVisible={isBusy} label="Checking" />
    </>
  );
}

export default NewPassword;
