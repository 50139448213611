import { useNavigate, Navigate } from 'react-router-dom';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RiLockPasswordLine } from 'react-icons/ri';
import { ButtonRow, FormInput, Loader, useAuth, useTrackedFormik } from '@omnigenbiodata/ui';
import MainLayout from '../../../../layout/Main';

import ROUTES from '../../../../core/constants/routes.constant';
import { MESSAGES, PASSWORD_REGEX } from '../../../../core/constants/forms.constant';

const validationSchema = yup.object().shape({
  code: yup.string().required(MESSAGES.emailRequired),
  password: yup.string().required(MESSAGES.passwordRequired).matches(PASSWORD_REGEX, MESSAGES.passwordFormat),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], MESSAGES.passwordNoMatch)
    .required(MESSAGES.passwordConfirmRequired),
});

function SubmitResetPassword() {
  const navigateTo = useNavigate();

  const {
    forgotPasswordSubmitHasError,
    isBusy,
    forgotPasswordEmail,
    forgotPasswordSubmitHasSuccess,
    forgotPasswordSubmit,
    forgotPasswordDeliveryDetails,
    reset,
  } = useAuth();

  const formik = useTrackedFormik(
    {
      initialValues: {
        email: forgotPasswordEmail,
        code: '',
        password: '',
        confirmPassword: '',
      },
      validationSchema,
      onSubmit: (values: any) => {
        forgotPasswordSubmit(values);
      },
    },
    'auth-submit-reset-password',
  );

  const handleCancel = () => {
    reset(); //TODO add to context
    navigateTo(ROUTES.auth);
  };

  return (
    <>
      {!forgotPasswordEmail && <Navigate to={ROUTES.authResetPassword} />}
      {forgotPasswordSubmitHasSuccess && <Navigate to={ROUTES.auth} />}
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={8}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Password Recovery
            </Typography>

            {forgotPasswordDeliveryDetails && forgotPasswordDeliveryDetails.DeliveryMedium === 'EMAIL' && (
              <Typography paragraph variant="body1" align="center">
                A 6-digit recovery code has been sent to your email address ({forgotPasswordDeliveryDetails.Destination}
                ).
              </Typography>
            )}

            {forgotPasswordDeliveryDetails && forgotPasswordDeliveryDetails.DeliveryMedium === 'SMS' && (
              <Typography paragraph variant="body1" align="center">
                A 6-digit recovery code has been sent to your mobile number ({forgotPasswordDeliveryDetails.Destination}
                ).
              </Typography>
            )}

            <Typography paragraph variant="body1" align="center">
              Please enter the code and a new password below:
            </Typography>
          </Box>
          {forgotPasswordSubmitHasError && (
            <Box mb={2}>
              <Alert severity="error">
                <Typography variant="body1" paragraph>
                  <strong>Incorrect Code</strong>
                </Typography>
                <Typography variant="body1">
                  Please check that you have entered the 6-digit recovery code correctly.
                </Typography>
              </Alert>
            </Box>
          )}
          <Box mb={8}>
            <FormInput
              error={formik.errors.code as string}
              name="code"
              label="Code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.code as boolean}
              value={formik.values.code}
            />
            <FormInput
              error={formik.errors.password as string}
              name="password"
              label="New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.password as boolean}
              value={formik.values.password}
              type="password"
              startAdornment={<RiLockPasswordLine />}
            />
            <FormInput
              error={formik.errors.confirmPassword as string}
              name="confirmPassword"
              label="Confirm New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.confirmPassword as boolean}
              value={formik.values.confirmPassword}
              type="password"
              startAdornment={<RiLockPasswordLine />}
            />
          </Box>
          <ButtonRow
            forwardLabel="Confirm"
            forwardColor="primary"
            buttonSize="large"
            backLabel="Cancel"
            onBack={handleCancel}
          />
        </form>
      </MainLayout>
      <Loader isVisible={isBusy} label="Checking" />
    </>
  );
}

export default SubmitResetPassword;
