import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { ButtonRow, FormStepper, FormInput, useTrackedFormik } from '@omnigenbiodata/ui';
import SignatureBlock from '../../../../components/content/SignatureBlock';

import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import { SAMPLE_STEPS, MESSAGES } from '../../../../core/constants/forms.constant';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { stepForward } from '../../../../store/samples';
import { formValueSelector } from '../../../../store/samples/selectors';
import { participantProfileSelector } from '../../../../store/participant/selectors';

function ParticipantSignature() {
  const dispatch = useAppDispatch();
  const values = useAppSelector(formValueSelector);
  const participantProfile = useAppSelector(participantProfileSelector);
  const navigateTo = useNavigate();

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(MESSAGES.firstNameRequired)
      .test('asyncFirstName', MESSAGES.participantFirstNameRequired, async (value) => {
        return participantProfile?.firstName?.toUpperCase() === value?.toUpperCase();
      }),
    surname: yup
      .string()
      .required(MESSAGES.lastNameRequired)
      .test('asyncFirstName', MESSAGES.participantLastNameRequired, async (value) => {
        return participantProfile?.surname?.toUpperCase() === value?.toUpperCase();
      }),
    participantSignature: yup.string().required(MESSAGES.sigRequired),
  });

  const formik = useTrackedFormik(
    {
      initialValues: {
        firstName: '',
        surname: '',
        participantSignature: '',
        ...values,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.samplesStaffMemberSignature);
      },
    },
    'samples-participant-signature',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={SAMPLE_STEPS} activeStep={4} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Participant signature
        </Typography>

        <Box mb={8}>
          <FormInput
            error={formik.errors.firstName}
            name="firstName"
            label="First name of participant"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.firstName}
            value={formik.values.firstName}
          />
          <FormInput
            error={formik.errors.surname}
            name="surname"
            label="Surname of participant"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.surname}
            value={formik.values.surname}
          />
        </Box>
        <Box mb={4}>
          <Grid container spacing={2}>
            <Grid item sm={2} />
            <Grid item sm={8}>
              <SignatureBlock
                error={formik.errors.participantSignature}
                name="participantSignature"
                label={`Please ask the participant to confirm they have reviewed the sample barcode labels and that the barcode ID (${values.serumBloodBarcode}) has been correctly entered into the sample collection form.`}
                value={formik.values.participantSignature}
                touched={formik.touched.participantSignature}
                setValue={formik.setFieldValue}
              />
            </Grid>
          </Grid>
        </Box>

        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default ParticipantSignature;
