import { useTheme } from '@mui/material/styles';
import { css } from '@emotion/react';

// ${...theme.typography.caption} needs adding to errorText
export const useStyles = () => {
  const theme = useTheme();
  return {
    signContainer: css`
      width: 100%;
      height: 300px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: ${theme.spacing(1)};
      border-width: 3px;
      border-radius: 20px;

      ${theme.breakpoints.up('md')} {
        width: 100%;
        height: 480px;
        max-width: 640px;
      }
    `,
    error: css`
      border-color: ${theme.palette.error.main};
    `,
    errorText: css`
      color: ${theme.palette.error.main};
    `,
    signPad: css`
      width: 100%;
      height: 100%;
    `,
    clearSig: css`
      display: block;
      margin: 0 auto;
      font-size: ${theme.typography.body1.fontSize};
    `,
  };
};
