import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { RiBarcodeFill } from 'react-icons/ri';
import { ButtonRow, FormInput, FormStepper, useTrackedFormik, Hidden } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import {
  MESSAGES,
  SAMPLE_BARCODE_LENGTH,
  SAMPLE_BARCODE_REGEX,
  SAMPLE_STEPS,
} from '../../../../core/constants/forms.constant';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { stepForward } from '../../../../store/samples';
import { formValueSelector } from '../../../../store/samples/selectors';

const validationSchema = yup.object({
  serumBloodBarcodeConfirm: yup
    .string()
    .oneOf([yup.ref('serumBloodBarcode')], MESSAGES.barcodeMismatch)
    .required(MESSAGES.barcodeRequired)
    .matches(SAMPLE_BARCODE_REGEX, MESSAGES.barcodeFormat)
    .min(SAMPLE_BARCODE_LENGTH, MESSAGES.barcodeLength)
    .max(SAMPLE_BARCODE_LENGTH, MESSAGES.barcodeLength),
});

function SerumConfirm() {
  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();
  const values = useAppSelector(formValueSelector);
  const formik = useTrackedFormik(
    {
      initialValues: {
        serumBloodBarcodeConfirm: '',
        ...values,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.samplesWholeBlood1);
      },
    },
    'samples-serum-confirm',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={SAMPLE_STEPS} activeStep={0} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Serum Blood Sample Confirmation
        </Typography>

        <Typography paragraph variant="body1" align="center">
          Confirm the barcode on the RED capped tube again using the attachable barcode scanner.
        </Typography>
        <Box mb={8}>
          <Grid container spacing={2}>
            <Hidden smDown>
              <Grid item md={2} />
            </Hidden>
            <Grid item xs={12} md={8}>
              <FormInput
                autoFocus
                error={formik.errors.serumBloodBarcodeConfirm}
                name="serumBloodBarcodeConfirm"
                label="Serum Sample Barcode Confirmation"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Confirm barcode"
                touched={formik.touched.serumBloodBarcodeConfirm}
                value={formik.values.serumBloodBarcodeConfirm}
                startAdornment={<RiBarcodeFill fontSize="large" />}
              />
            </Grid>
          </Grid>
        </Box>

        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default SerumConfirm;
