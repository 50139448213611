import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { ButtonRow, useAuth } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';

function Success() {
  const navigateTo = useNavigate();
  const { signOut } = useAuth();

  return (
    <InnerLayout>
      <Typography variant="h4" component="h1" align="center" paragraph>
        Enrolment Complete
      </Typography>
      <Box mb={2}>
        <Alert severity="success">
          <Typography variant="body1" component="p" gutterBottom>
            <strong>The participant has now successfully enrolled in the Discover Me South Africa study.</strong>
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            Please select one of the below options to either continue to sample collection or return to the home page to
            enrol a new participant
          </Typography>
        </Alert>
      </Box>
      <ButtonRow
        forwardLabel="Sample Collection"
        backLabel="End Enrolment"
        forwardColor="primary"
        buttonSize="large"
        onBack={() => {
          signOut();
        }}
        onForward={() => {
          navigateTo(ROUTES.samples);
        }}
      />
    </InnerLayout>
  );
}

export default Success;
