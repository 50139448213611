import Typography from '@mui/material/Typography';

import InnerLayout from '../../layout/Inner';
import ParticipantVideo from '../../components/content/ParticipantVideo';
import LangauageToggle from '../../components/content/LangauageToggle';

function Video() {
  return (
    <InnerLayout>
      <Typography variant="h4" component="h1" align="center">
        Enrolment Video
      </Typography>
      <LangauageToggle />
      <ParticipantVideo title="Enrolment Video" />
    </InnerLayout>
  );
}

export default Video;
