import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { ButtonRow, FormStepper } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import { ENROLMENT_STEPS } from '../../../../core/constants/forms.constant';
import { useAppSelector, useAppDispatch } from '../../../../store';
import { formValueSelector } from '../../../../store/enrolment/selectors';
import { resetEnrolment } from '../../../../store/enrolment';

function Ineligible() {
  const navigateTo = useNavigate();
  const values = useAppSelector(formValueSelector);
  const dispatch = useAppDispatch();

  const handleEndEnrolment = (): void => {
    dispatch(resetEnrolment());
    navigateTo(ROUTES.root);
  };

  return (
    <InnerLayout>
      <Box my={3}>
        <FormStepper steps={ENROLMENT_STEPS} activeStep={0} />
      </Box>
      <Typography variant="h4" component="h1" align="center" paragraph>
        Confirmation of ineligibility
      </Typography>
      <Box mb={2}>
        <Alert severity="error">
          {!values.eligibility1 && !values.eligibility2 && (
            <Typography variant="body1" paragraph>
              <strong>
                You have indicated that the participant is not aged 18 years or over and not a patient of IALCH.
              </strong>
            </Typography>
          )}
          {!values.eligibility1 && values.eligibility2 && (
            <Typography variant="body1" paragraph>
              <strong>You have indicated that the participant is not aged 18 years or over</strong>
            </Typography>
          )}
          {values.eligibility1 && !values.eligibility2 && (
            <Typography variant="body1" paragraph>
              <strong>You have indicated that the participant is not a patient of IALCH.</strong>
            </Typography>
          )}
          <Typography variant="body1" paragraph>
            Unfortunately, the participant is not eligible to take part in Discover Me South Africa. To be eligible to
            take part in Discover Me South Africa participants must be aged 18 years or over, and be a patient at IALCH.
          </Typography>
          <Typography variant="body1">
            If you have made this choice in error, select <strong>Back</strong> to go to the previous page. Otherwise,
            select <strong>End enrolment</strong>
          </Typography>
        </Alert>
      </Box>
      <ButtonRow
        showForward
        onForward={handleEndEnrolment}
        forwardLabel="End Enrolment"
        forwardColor="error"
        buttonSize="large"
      />
    </InnerLayout>
  );
}

export default Ineligible;
