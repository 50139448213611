import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RiSyringeLine, RiUser2Line } from 'react-icons/ri';
import { ButtonRow, FormStepper, Loader, SummaryTable, useMatomo } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import { SUPPORT_EMAIL } from '../../../../core/constants/content.constant';
import { SAMPLE_STEPS } from '../../../../core/constants/forms.constant';
import { useAppSelector, useAppDispatch } from '../../../../store';
import { submitSamples } from '../../../../store/samples';
import {
  formValueSelector,
  hasSuccessSelector,
  isBusySelector,
  hasErrorSelector,
} from '../../../../store/samples/selectors';

function Confirm() {
  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();
  const values = useAppSelector(formValueSelector);
  const hasSubmissionError = useAppSelector(hasErrorSelector);
  const hasSubmissionSuccess = useAppSelector(hasSuccessSelector);
  const isBusy = useAppSelector(isBusySelector);
  const { trackEvent } = useMatomo();

  useEffect(() => {
    if (hasSubmissionSuccess) {
      navigateTo(ROUTES.samplesSuccess);
    }
  }, [hasSubmissionSuccess, navigateTo]);

  const handleSubmit = () => {
    trackEvent({
      category: 'samples',
      action: `submit samples`,
    });
    dispatch(submitSamples(values));
  };

  return (
    <>
      <InnerLayout>
        <Box my={3}>
          <FormStepper steps={SAMPLE_STEPS} activeStep={5} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Confirm Sample Collection
        </Typography>
        <Typography paragraph variant="body1" align="center">
          If the details are correct please select Confirm to submit the form
        </Typography>

        {hasSubmissionError && (
          <Box mb={2}>
            <Alert severity="error">
              <Typography variant="body1" paragraph>
                <strong>Sample Submission Failed</strong>
              </Typography>
              <Typography variant="body1">
                An unexpected error occurred. Please try again. If the problem persists contact{' '}
                <strong>{SUPPORT_EMAIL}</strong>
              </Typography>
            </Alert>
          </Box>
        )}

        <Box mb={2} pl={2} style={{ borderLeft: '10px solid grey' }}>
          <Typography variant="h6" component="h2" paragraph>
            <RiUser2Line
              fontSize="inherit"
              style={{
                marginRight: 10,
                fontSize: '1.4em',
                verticalAlign: 'middle',
              }}
            />
            Discover Me research team member taking samples
          </Typography>
          <SummaryTable
            rows={[
              {
                key: 'staffMemberFirstName',
                label: 'First Name',
                value: values['staffMemberFirstName'],
              },
              {
                key: 'staffMemberSurname',
                label: 'Surname',
                value: values['staffMemberSurname'],
              },
            ]}
            label="Discover Me research team member"
          />
        </Box>

        {values['firstName'] && values['surname'] && (
          <Box mb={2} pl={2} style={{ borderLeft: '10px solid grey' }}>
            <Typography variant="h6" component="h2" paragraph>
              <RiUser2Line
                fontSize="inherit"
                style={{
                  marginRight: 10,
                  fontSize: '1.4em',
                  verticalAlign: 'middle',
                }}
              />
              Participant
            </Typography>
            <SummaryTable
              rows={[
                {
                  key: 'participantFirstName',
                  label: 'First Name',
                  value: values['firstName'],
                },
                {
                  key: 'participantSurname',
                  label: 'Surname',
                  value: values['surname'],
                },
              ]}
              label="Participant"
            />
          </Box>
        )}

        {values['witnessFirstName'] && values['witnessSurname'] && (
          <Box mb={2} pl={2} style={{ borderLeft: '10px solid grey' }}>
            <Typography variant="h6" component="h2" paragraph>
              <RiUser2Line
                fontSize="inherit"
                style={{
                  marginRight: 10,
                  fontSize: '1.4em',
                  verticalAlign: 'middle',
                }}
              />
              Witness
            </Typography>
            <SummaryTable
              rows={[
                {
                  key: 'witnessFirstName',
                  label: 'Witness First Name',
                  value: values['witnessFirstName'],
                },
                {
                  key: 'witnessSurname',
                  label: 'Witness Surname',
                  value: values['witnessSurname'],
                },
              ]}
              label="Witness"
            />
          </Box>
        )}

        <Box mb={2} pl={2} style={{ borderLeft: '10px solid red' }}>
          <Typography variant="h6" component="h2" paragraph>
            <RiSyringeLine
              fontSize="inherit"
              style={{
                marginRight: 10,
                fontSize: '1.4em',
                verticalAlign: 'middle',
              }}
            />
            Serum Blood
          </Typography>
          <SummaryTable
            rows={[
              {
                key: 'serumBloodStatus',
                label: 'Status',
                value: values['serumBloodStatus'],
              },
              {
                key: 'serumBloodBarcode',
                label: 'Barcode',
                value: values['serumBloodBarcode'],
              },
              {
                key: 'serumBloodCollectionDateTime',
                label: 'Collection Time',
                format: 'friendlyDateTime',
                pattern: 'MMMM D, YYYY HH:mm',
                hideIfEmpty: true,
                value: values['serumBloodCollectionDateTime'],
              },
            ]}
            label="Serum Blood"
          />
        </Box>
        <Box mb={2} pl={2} style={{ borderLeft: '10px solid purple' }}>
          <Typography variant="h6" component="h2" paragraph>
            <RiSyringeLine
              fontSize="inherit"
              style={{
                marginRight: 10,
                fontSize: '1.4em',
                verticalAlign: 'middle',
              }}
            />
            Whole Blood
          </Typography>
          <SummaryTable
            rows={[
              {
                key: 'wholeBloodStatus',
                label: 'Status',
                value: values['wholeBloodStatus'],
              },
              {
                key: 'wholeBloodBarcode',
                label: 'Barcode',
                value: values['wholeBloodBarcode'],
              },
              {
                key: 'wholeBloodCollectionDateTime',
                label: 'Collection Time',
                format: 'friendlyDateTime',
                pattern: 'MMMM D, YYYY HH:mm',
                hideIfEmpty: true,
                value: values['wholeBloodCollectionDateTime'],
              },
            ]}
            label="Whole Blood"
          />
        </Box>
        <Box mb={8} pl={2} style={{ borderLeft: '10px solid purple' }}>
          <Typography variant="h6" component="h2" paragraph>
            <RiSyringeLine
              fontSize="inherit"
              style={{
                marginRight: 10,
                fontSize: '1.4em',
                verticalAlign: 'middle',
              }}
            />
            Plasma Blood
          </Typography>
          <SummaryTable
            rows={[
              {
                key: 'plasmaBloodStatus',
                label: 'Status',
                value: values['plasmaBloodStatus'],
              },
              {
                key: 'plasmaBloodBarcode',
                label: 'Barcode',
                value: values['plasmaBloodBarcode'],
              },
              {
                key: 'plasmaBloodCollectionDateTime',
                label: 'Collection Time',
                format: 'friendlyDateTime',
                pattern: 'MMMM D, YYYY HH:mm',
                hideIfEmpty: true,
                value: values['plasmaBloodCollectionDateTime'],
              },
            ]}
            label="Whole Blood"
          />
        </Box>
        <ButtonRow onForward={handleSubmit} forwardLabel="Confirm" forwardColor="primary" buttonSize="large" />
      </InnerLayout>
      <Loader label="Submitting Samples" isVisible={isBusy} />
    </>
  );
}

export default Confirm;
