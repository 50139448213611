import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { ButtonRow, FormInput, FormStepper, authAPI, useTrackedFormik } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';

import ROUTES from '../../../../core/constants/routes.constant';
import { ENROLMENT_STEPS, MESSAGES, TOTAL_CONSENT_STEPS } from '../../../../core/constants/forms.constant';
// import authAPI from "../../../../store/auth/authApi";

const validationSchema = yup.object({
  password: yup.string().required(MESSAGES.passwordRequired),
});

function Consent11() {
  const navigateTo = useNavigate();
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  const formik = useTrackedFormik(
    {
      initialValues: { password: '' },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        setErrorMessage(false);

        const { password } = values;
        const response = await authAPI.checkSignIn(password);

        if (response.data) {
          navigateTo(ROUTES.enrolmentConsent12);
        } else {
          setErrorMessage(true);
        }
      },
    },
    'enrol-staff-verification',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={1} />
        </Box>
        <Typography variant="h4" component="h1" align="center">
          9. Signatures
        </Typography>
        <Typography variant="h5" component="h2" align="center" gutterBottom>
          Discover Me research team member password
        </Typography>
        <MobileStepper
          backButton={false}
          nextButton={false}
          variant="dots"
          steps={TOTAL_CONSENT_STEPS}
          position="static"
          activeStep={8}
        />

        <Typography variant="body1" component="p" align="center" paragraph>
          Please verify your password to continue with enrolment.
        </Typography>

        {errorMessage && (
          <Box mb={2}>
            <Alert severity="error">
              <Typography variant="body1" paragraph>
                <strong>Invalid Password</strong>
              </Typography>
              <Typography variant="body1">The password did not match. Please try again.</Typography>
            </Alert>
          </Box>
        )}

        <Box mb={8}>
          <FormInput
            error={formik.errors.password}
            name="password"
            type="password"
            label="Confirm Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.password}
            value={formik.values.password}
          />
        </Box>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Consent11;
