import { useState, ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as yup from 'yup';

import { RiFingerprintLine } from 'react-icons/ri';
import { FaSignature } from 'react-icons/fa';
import {
  ButtonRow,
  FormInput,
  FormStepper,
  CaptureBlock,
  Loader,
  authAPI,
  useTrackedFormik,
  useMatomo,
} from '@omnigenbiodata/ui';
import SignatureBlock from '../../../../components/content/SignatureBlock';
import { useTranslation } from 'react-i18next';

import InnerLayout from '../../../../layout/Inner';
import { ENROLMENT_STEPS, MESSAGES, TOTAL_CONSENT_STEPS } from '../../../../core/constants/forms.constant';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { stepForward } from '../../../../store/enrolment';
import { uploadImage } from '../../../../store/uploads';
import { filenameSelector, isBusySelector, hasErrorSelector } from '../../../../store/uploads/selectors';

import ROUTES from '../../../../core/constants/routes.constant';
import ConfirmNameMatchDialog from './components/ConfirmNameMatchDialog';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const validationSchema = yup.object({
  firstName: yup.string().required(MESSAGES.firstNameRequired),
  surname: yup.string().required(MESSAGES.lastNameRequired),
  participantSignature: yup.string().test('oneOfRequired', MESSAGES.fingerPrintorSigRequired, function () {
    return this.parent.participantSignature || this.parent.participantFingerprint;
  }),
  participantFingerprint: yup.string().test('oneOfRequired', MESSAGES.fingerPrintorSigRequired, function () {
    return this.parent.participantSignature || this.parent.participantFingerprint;
  }),
});

function Consent9() {
  const { t } = useTranslation('consent');
  const dispatch = useAppDispatch();
  const [currentTab, setTab] = useState('1');
  const [isNameMatch, setIsNameMatch] = useState(false);
  const uploadedFilename = useAppSelector(filenameSelector);
  const isUploadBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const { trackEvent } = useMatomo();

  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        firstName: '',
        surname: '',
        participantSignature: '',
        participantFingerprint: '',
        participantFingerprintFilename: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        checkNames(values);
      },
    },
    'enrol-participant-signature',
  );

  useEffect(() => {
    if (hasError) {
      formik.setFieldValue('participantFingerprint', '');
      formik.setFieldValue('participantFingerprintFilename', '');
    }

    if (
      uploadedFilename &&
      formik.values.participantFingerprint &&
      formik.values.participantFingerprintFilename !== uploadedFilename
    ) {
      trackEvent({ category: 'participantFingerprint', action: 'upload' });
      formik.setFieldValue('participantFingerprintFilename', uploadedFilename);
    }
  }, [hasError, uploadedFilename, formik, trackEvent]);

  const handleTabChange = (event: ChangeEvent<object>, value: any) => {
    setTab(value);
    if (value === '1') {
      trackEvent({ category: 'change tab', action: 'fingerprint' });
      formik.setFieldValue('participantFingerprint', '');
    } else {
      trackEvent({ category: 'change tab', action: 'signature' });
      formik.setFieldValue('participantSignature', '');
    }
  };

  const checkNames = async (values: any) => {
    const {
      attributes: { given_name: givenName, family_name: familyName },
    } = await authAPI.getCurrentUser();

    if (givenName === values.firstName && familyName === values.surname) {
      setIsNameMatch(true);
    } else {
      setIsNameMatch(false);
      submitForm(values);
    }
  };

  const submitForm = async (values: any) => {
    dispatch(stepForward(values));
    if (currentTab === '1') {
      navigateTo(ROUTES.enrolmentConsent11);
    } else {
      navigateTo(ROUTES.enrolmentConsent10);
    }
  };

  return (
    <>
      <InnerLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box my={3}>
            <FormStepper steps={ENROLMENT_STEPS} activeStep={1} />
          </Box>
          <Typography variant="h4" component="h1" align="center">
            9. {t('section9.title')}
          </Typography>
          <Typography variant="h5" component="h2" align="center" gutterBottom>
            {t('section9.participant.title')}
          </Typography>
          <MobileStepper
            backButton={false}
            nextButton={false}
            variant="dots"
            steps={TOTAL_CONSENT_STEPS}
            position="static"
            activeStep={8}
          />
          <Box mb={4}>
            <FormInput
              error={formik.errors.firstName}
              name="firstName"
              label={t('section9.participant.firstName')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.firstName}
              value={formik.values.firstName}
            />
            <FormInput
              error={formik.errors.surname}
              name="surname"
              label={t('section9.participant.surname')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.surname}
              value={formik.values.surname}
            />
          </Box>
          <Box mb={4}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={currentTab}
                centered
                onChange={handleTabChange}
                aria-label={t('section9.participant.signatureOrFingerprint')}
              >
                <Tab
                  label={t('section9.participant.signature')}
                  value="1"
                  {...a11yProps(0)}
                  icon={<FaSignature fontSize={40} />}
                />
                <Tab
                  label={t('section9.participant.fingerprint')}
                  value="2"
                  {...a11yProps(1)}
                  icon={<RiFingerprintLine fontSize={40} />}
                />
              </Tabs>
            </Box>
            <TabPanel index="1" value={currentTab}>
              <SignatureBlock
                error={formik.errors.participantSignature}
                name="participantSignature"
                label="Please ask the participant to sign the consent form"
                value={formik.values.participantSignature}
                touched={formik.touched.participantSignature}
                setValue={formik.setFieldValue}
              />
            </TabPanel>
            <TabPanel index="2" value={currentTab}>
              <div style={{ zIndex: 0, position: 'relative' }}>
                <CaptureBlock
                  label={t('section9.participant.fingerprint')}
                  name="participantFingerprint"
                  onBlur={formik.handleBlur}
                  value={formik.values.participantFingerprint}
                  setValue={(name, value) => {
                    formik.setFieldValue(name, value);
                    if (value) {
                      dispatch(uploadImage(value));
                    }
                  }}
                  error={formik.errors.participantFingerprint}
                  touched={formik.touched.participantFingerprint}
                />
              </div>
            </TabPanel>
          </Box>
          <Loader label="Sending fingerprints" isVisible={isUploadBusy} />
          <ButtonRow isDisabled={isUploadBusy || hasError} forwardColor="primary" />
        </form>
        <ConfirmNameMatchDialog
          isModalOpen={isNameMatch}
          onConfirm={() => {
            submitForm(formik.values);
          }}
          onClose={() => {
            setIsNameMatch(false);
          }}
        />
      </InnerLayout>
    </>
  );
}

export default Consent9;
