import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { ButtonRow, FormStepper, FormSelect, useTrackedFormik } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../layout/Inner';
import { useAppDispatch } from '../../../../store';
import { stepForward } from '../../../../store/enrolment';
import { DEPARTMENT_OPTIONS, ENROLMENT_STEPS, MESSAGES } from '../../../../core/constants/forms.constant';
import ROUTES from '../../../../core/constants/routes.constant';

const validationSchema = yup.object({
  departmentOfEnrolment: yup.string().required(MESSAGES.departmentRequired),
});

function Department() {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        departmentOfEnrolment: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.enrolmentLanguage);
      },
    },
    'enrol-department',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit} data-testid="step-form">
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={0} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Department Selection
        </Typography>

        <Typography paragraph variant="body1" align="center">
          Please select the department you are enrolling a participant in
        </Typography>
        <Box mb={8}>
          <FormSelect
            error={formik.errors.departmentOfEnrolment}
            name="departmentOfEnrolment"
            label="Department"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={DEPARTMENT_OPTIONS}
            placeholder=""
            touched={formik.touched.departmentOfEnrolment}
            value={formik.values.departmentOfEnrolment}
          />
        </Box>
        <ButtonRow showBack={false} buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Department;
