import { RootState } from '../index';
import { ParticipantEnrolment, ParticipantProfile, ParticipantSample } from './types';
import { KeyValues, StoreStatus } from '../../core/types/common.types';

export const hasLookupErrorSelector = (state: RootState): boolean =>
  state.participant.errors && state.participant.errors.length > 0 ? true : false;

export const lookupErrorMessagesSelector = (state: RootState): string[] =>
  state.participant.errors ? state.participant.errors.map((error) => error.message) : [];

export const hasLookupSuccessSelector = (state: RootState): boolean => (state.participant.result?.id ? true : false);

export const participantIdSelector = (state: RootState): string | null =>
  state.participant.result?.id ? state.participant.result?.id : null;

export const participantProfileSelector = (state: RootState): ParticipantProfile | null =>
  state.participant.result?.profile ? state.participant.result?.profile : null;

export const participantEnrolmentSelector = (state: RootState): ParticipantEnrolment | null =>
  state.participant.result?.enrolment ? state.participant.result?.enrolment : null;

export const participantSamplesSelector = (state: RootState): ParticipantSample | null =>
  state.participant.result?.sample ? state.participant.result?.sample : null;

export const hasParticipantSamples = (state: RootState): boolean =>
  state.participant.result?.sample?.serumStatus ? true : false;

export const formValueSelector = (state: RootState): KeyValues => state.participant.values || {};

export const isBusySelector = (state: RootState): boolean => state.participant.status === StoreStatus.BUSY;

export const consentVersionSelector = (state: RootState): string | null => state.participant.consentResult;

export const isConsentVersionBusySelector = (state: RootState): boolean =>
  state.participant.consentStatus === StoreStatus.BUSY;

export const hasConsentFormErrorSelector = (state: RootState): boolean =>
  state.participant.consentErrors ? true : false;
