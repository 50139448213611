import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { ButtonRow, FormStepper, FormButtonGroup, useTrackedFormik } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../layout/Inner';
import { useAppDispatch } from '../../../../store';
import { stepForward } from '../../../../store/enrolment';

import { ENROLMENT_STEPS, LANGUAGE_OPTIONS, MESSAGES } from '../../../../core/constants/forms.constant';
import ROUTES from '../../../../core/constants/routes.constant';

const validationSchema = yup.object({
  languagePreference: yup.string().required(MESSAGES.languageRequired),
});

function Language() {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        languagePreference: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        i18n.changeLanguage(values.languagePreference);
        dispatch(stepForward(values));
        navigateTo(ROUTES.enrolmentEligibility);
      },
    },
    'enrol-language',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={0} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Language
        </Typography>
        <Typography paragraph variant="body1" align="center">
          Please select the participant&apos;s preferred language
        </Typography>

        <Box mb={8}>
          <FormButtonGroup
            hideLabel
            error={formik.errors.languagePreference}
            name="languagePreference"
            label="Language"
            setValue={formik.setFieldValue}
            options={LANGUAGE_OPTIONS}
            touched={formik.touched.languagePreference}
            value={formik.values.languagePreference}
          />
        </Box>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Language;
