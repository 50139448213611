import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { ButtonRow, FormCheckBox, FormStepper, useTrackedFormik } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../layout/Inner';
import { stepForward } from '../../../../store/enrolment';
import { useAppDispatch } from '../../../../store';
import { ENROLMENT_STEPS, MESSAGES } from '../../../../core/constants/forms.constant';
import ROUTES from '../../../../core/constants/routes.constant';
import { STUDY_ADDRESS, STUDY_EMAIL, STUDY_TEL, STUDY_URL } from '../../../../core/constants/content.constant';
import ParticipantInfo from '../../../../components/content/ParticipantInfo';

const validationSchema = yup.object({
  confirmPis: yup.boolean().oneOf([true], MESSAGES.informationSheetRequired),
});

function InformationSheet() {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        confirmPis: false,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.enrolmentConsent);
      },
    },
    'enrol-pis',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={0} />
        </Box>
        <Typography variant="h4" component="h1" align="center" paragraph>
          Participant Information Sheet
        </Typography>
        <Typography paragraph variant="body1" align="left">
          Participants should read the Discover Me South Africa participant information sheet, or have it read to them,
          at least once in full before proceeding with enrolment.
        </Typography>
        <Typography paragraph variant="body1" align="left">
          If this has not happened, you should read the study information sheet to the participant, taking care to read
          each section as it is written. Participants may also choose to read the participant information sheet on their
          own.
        </Typography>
        <Typography paragraph variant="body1" align="left">
          If a participant cannot read, please ensure a literate witness is present when reading out the participant
          information sheet.
        </Typography>
        <Typography paragraph variant="body1" align="left">
          Remember to ask the participant if they have any questions, or if there is anything they don&apos;t
          understand.
        </Typography>

        <Box mb={2} mt={4}>
          <ParticipantInfo address={STUDY_ADDRESS} email={STUDY_EMAIL} telephone={STUDY_TEL} web={STUDY_URL} />
        </Box>

        <Box mb={8} mt={4}>
          <FormCheckBox
            error={''}
            name="confirmPis"
            label={
              'I confirm that the participant has read the information sheet or it has been read out to them (and, if required, their witness);'
            }
            onChange={formik.handleChange}
            touched={formik.touched.confirmPis}
            value={formik.values.confirmPis}
          />
          <Box pl={4} pt={1}>
            <Typography variant="body1" paragraph>
              I confirm that the participant has been given the opportunity to ask questions and has had their questions
              answered; and
            </Typography>
            <Typography variant="body1" paragraph>
              I confirm that I have assessed the participant&apos;s capacity to consent and confirm that the participant
              is able to make their own decision to participate in the study.
            </Typography>
            {formik.errors.confirmPis && (
              <Typography paragraph variant="body2" align="left" color="error">
                {formik.errors.confirmPis}
              </Typography>
            )}
          </Box>
        </Box>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default InformationSheet;
