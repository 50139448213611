import { RootState } from '../index';
import { KeyValues, StoreStatus } from '../../core/types/common.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.samples.errors && state.samples.errors.length > 0 ? true : false;

export const errorMessagesSelector = (state: RootState): string[] =>
  state.samples.errors ? state.samples.errors.map((error) => error.message) : [];

export const hasSuccessSelector = (state: RootState): boolean => (state.samples.result?.participant?.id ? true : false);

export const participantIdSelector = (state: RootState): string | null =>
  state.samples.result?.participant?.id ? state.samples.result?.participant?.id : null;

export const formValueSelector = (state: RootState): KeyValues => state.samples.values || {};

export const isBusySelector = (state: RootState): boolean => state.samples.status === StoreStatus.BUSY;
