import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { ButtonRow, FormStepper, FormInput, authAPI, useTrackedFormik } from '@omnigenbiodata/ui';
import SignatureBlock from '../../../../components/content/SignatureBlock';

import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import { SAMPLE_STEPS, MESSAGES } from '../../../../core/constants/forms.constant';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { stepForward } from '../../../../store/samples';
import { formValueSelector } from '../../../../store/samples/selectors';

function StaffSignature() {
  const dispatch = useAppDispatch();
  const values = useAppSelector(formValueSelector);
  const navigateTo = useNavigate();

  const validationSchema = yup.object({
    staffMemberFirstName: yup
      .string()
      .required(MESSAGES.firstNameRequired)
      .test('asyncFirstName', MESSAGES.userFirstNameRequired, async (value) => {
        try {
          const {
            attributes: { given_name: givenName },
          } = await authAPI.getCurrentUser();
          return givenName === value;
        } catch {
          return false;
        }
      }),
    staffMemberSurname: yup
      .string()
      .required(MESSAGES.lastNameRequired)
      .test('asyncLastName', MESSAGES.userLastNameRequired, async (value) => {
        try {
          const {
            attributes: { family_name: familyName },
          } = await authAPI.getCurrentUser();
          return familyName === value;
        } catch {
          return false;
        }
      }),
    staffMemberSignature: yup.string().required(MESSAGES.staffSigRequired),
  });

  const formik = useTrackedFormik(
    {
      initialValues: {
        staffMemberFirstName: '',
        staffMemberSurname: '',
        staffMemberSignature: '',
        ...values,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.samplesConfirm);
      },
    },
    'samples-staff-signature',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={SAMPLE_STEPS} activeStep={4} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Discover Me research team member signature
        </Typography>

        <Box mb={8}>
          <FormInput
            error={formik.errors.staffMemberFirstName}
            name="staffMemberFirstName"
            label="First name of Discover Me research team member taking samples"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.staffMemberFirstName}
            value={formik.values.staffMemberFirstName}
          />
          <FormInput
            error={formik.errors.staffMemberSurname}
            name="staffMemberSurname"
            label="Surname of Discover Me research team member taking samples"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.staffMemberSurname}
            value={formik.values.staffMemberSurname}
          />
        </Box>
        <Box mb={4}>
          <Grid container spacing={2}>
            <Grid item sm={2} />
            <Grid item sm={8}>
              <SignatureBlock
                error={formik.errors.staffMemberSignature}
                name="staffMemberSignature"
                label="Please sign below"
                value={formik.values.staffMemberSignature}
                touched={formik.touched.staffMemberSignature}
                setValue={formik.setFieldValue}
              />
            </Grid>
          </Grid>
        </Box>

        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default StaffSignature;
