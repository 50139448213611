import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthRoute } from '@omnigenbiodata/ui';
import Authentication from './Authentication';
import Login from './Authentication/scenes/Login';
import Logout from './Authentication/scenes/Logout';
import ResetPassword from './Authentication/scenes/ResetPassword';
import SubmitResetPassword from './Authentication/scenes/SubmitResetPassword';
import Enrolment from './Enrolment';
import HospitalNum from './Enrolment/scenes/HospitalNum';
import Department from './Enrolment/scenes/Department';
import Language from './Enrolment/scenes/Language';
import Eligibile from './Enrolment/scenes/Eligibile';
import Ineligible from './Enrolment/scenes/Ineligible';
import EnrolmentVideo from './Enrolment/scenes/Video';
import InformationSheet from './Enrolment/scenes/InformationSheet';
import Consent from './Enrolment/scenes/Consent';
import Consent1 from './Enrolment/scenes/Consent1';
import Consent2 from './Enrolment/scenes/Consent2';
import Consent3 from './Enrolment/scenes/Consent3';
import Consent4 from './Enrolment/scenes/Consent4';
import Consent5 from './Enrolment/scenes/Consent5';
import Consent6 from './Enrolment/scenes/Consent6';
import Consent7 from './Enrolment/scenes/Consent7';
import Consent8 from './Enrolment/scenes/Consent8';
import Consent9 from './Enrolment/scenes/Consent9';
import Consent10 from './Enrolment/scenes/Consent10';
import Consent11 from './Enrolment/scenes/Consent11';
import Consent12 from './Enrolment/scenes/Consent12';
import Details1 from './Enrolment/scenes/Details1';
import Details2 from './Enrolment/scenes/Details2';
import EnrolmentReview from './Enrolment/scenes/Review';
import EnrolmentSuccess from './Enrolment/scenes/Success';
import Home from './Home';
import Information from './Information';
import Samples from './Samples';
import ParticipantLookup from './Samples/scenes/ParticipantLookup';
import ParticipantResult from './Samples/scenes/ParticipantResult';
import SerumStatus from './Samples/scenes/SerumStatus';
import SerumScan from './Samples/scenes/SerumScan';
import SerumConfirm from './Samples/scenes/SerumConfirm';
import WholeBloodStatus from './Samples/scenes/WholeBloodStatus';
import WholeBloodScan from './Samples/scenes/WholeBloodScan';
import PlasmaBloodStatus from './Samples/scenes/PlasmaBloodStatus';
import PlasmaBloodScan from './Samples/scenes/PlasmaBloodScan';
import ParticipantSignature from './Samples/scenes/ParticipantSignature';
import StaffSignature from './Samples/scenes/StaffSignature';
import SamplesReview from './Samples/scenes/Review';
import SamplesConfirm from './Samples/scenes/Confirm';
import SamplesSuccess from './Samples/scenes/Success';
import Video from './Video';
import ROUTES from '../core/constants/routes.constant';
import WitnessSignature from './Samples/scenes/WitnessSignature';

function Scenes() {
  return (
    <Routes>
      <Route path={ROUTES.auth} element={<Authentication />}>
        <Route path={ROUTES.authResetPassword} element={<ResetPassword />} />
        <Route path={ROUTES.authSubmitResetPassword} element={<SubmitResetPassword />} />
        <Route path={ROUTES.authSignOut} element={<Logout />} />
        <Route path={ROUTES.auth} element={<Login />} />
      </Route>
      <Route path={ROUTES.enrolment} element={<AuthRoute path={ROUTES.enrolment} element={Enrolment} />}>
        <Route path={ROUTES.enrolment} element={<HospitalNum />} />
        <Route path={ROUTES.enrolmentDepartment} element={<Department />} />
        <Route path={ROUTES.enrolmentLanguage} element={<Language />} />
        <Route path={ROUTES.enrolmentEligibility} element={<Eligibile />} />
        <Route path={ROUTES.enrolmentIneligible} element={<Ineligible />} />
        <Route path={ROUTES.enrolmentVideo} element={<EnrolmentVideo />} />
        <Route path={ROUTES.enrolmentPis} element={<InformationSheet />} />
        <Route path={ROUTES.enrolmentConsent} element={<Consent />} />
        <Route path={ROUTES.enrolmentConsent1} element={<Consent1 />} />
        <Route path={ROUTES.enrolmentConsent2} element={<Consent2 />} />
        <Route path={ROUTES.enrolmentConsent3} element={<Consent3 />} />
        <Route path={ROUTES.enrolmentConsent4} element={<Consent4 />} />
        <Route path={ROUTES.enrolmentConsent5} element={<Consent5 />} />
        <Route path={ROUTES.enrolmentConsent6} element={<Consent6 />} />
        <Route path={ROUTES.enrolmentConsent7} element={<Consent7 />} />
        <Route path={ROUTES.enrolmentConsent8} element={<Consent8 />} />
        <Route path={ROUTES.enrolmentConsent9} element={<Consent9 />} />
        <Route path={ROUTES.enrolmentConsent10} element={<Consent10 />} />
        <Route path={ROUTES.enrolmentConsent11} element={<Consent11 />} />
        <Route path={ROUTES.enrolmentConsent12} element={<Consent12 />} />
        <Route path={ROUTES.enrolmentDetails1} element={<Details1 />} />
        <Route path={ROUTES.enrolmentDetails2} element={<Details2 />} />
        <Route path={ROUTES.enrolmentReview} element={<EnrolmentReview />} />
        <Route path={ROUTES.enrolmentSuccess} element={<EnrolmentSuccess />} />
      </Route>
      <Route path={ROUTES.samples} element={<AuthRoute path={ROUTES.samples} element={Samples} />}>
        <Route path={ROUTES.samples} element={<ParticipantLookup />} />
        <Route path={ROUTES.samplesSerum1} element={<SerumStatus />} />
        <Route path={ROUTES.samplesSerum2} element={<SerumScan />} />
        <Route path={ROUTES.samplesSerum3} element={<SerumConfirm />} />
        <Route path={ROUTES.samplesWholeBlood1} element={<WholeBloodStatus />} />
        <Route path={ROUTES.samplesWholeBlood2} element={<WholeBloodScan />} />
        <Route path={ROUTES.samplesPlasma1} element={<PlasmaBloodStatus />} />
        <Route path={ROUTES.samplesPlasma2} element={<PlasmaBloodScan />} />
        <Route path={ROUTES.samplesReview} element={<SamplesReview />} />
        <Route path={ROUTES.samplesParticipantSignature} element={<ParticipantSignature />} />
        <Route path={ROUTES.samplesWitnessSignature} element={<WitnessSignature />} />
        <Route path={ROUTES.samplesStaffMemberSignature} element={<StaffSignature />} />

        <Route path={ROUTES.samplesConfirm} element={<SamplesConfirm />} />
        <Route path={ROUTES.samplesSuccess} element={<SamplesSuccess />} />
        <Route path={ROUTES.samplesResult} element={<ParticipantResult />} />
      </Route>
      <Route path={ROUTES.information} element={<Information />} />
      <Route path={ROUTES.video} element={<Video />} />
      <Route path={ROUTES.root} element={<Home />} />
      <Route path="*" element={<Navigate to={ROUTES.root} replace />} />
    </Routes>
  );
}

export default Scenes;
