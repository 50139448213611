import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import uploadsApi from './uploadsApi';
import { SampleState, UploadRejectionResponse, UploadResponse } from './types';
import { StoreStatus } from '../../core/types/common.types';

// Initial State
const initialState: SampleState = {
  errors: null,
  result: null,
  status: StoreStatus.IDLE,
  values: null,
};

// Thunks
export const uploadImage = createAsyncThunk<UploadResponse, string, UploadRejectionResponse>(
  'uploads/submit',
  async (image, { rejectWithValue }) => {
    try {
      return await uploadsApi.uploadImage(image);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

// Slice
export const uploadSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    resetUploads: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(uploadImage.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });
    builder.addCase(uploadImage.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.result = action.payload;
      state.errors = null;
      state.values = null;
    });
    builder.addCase(uploadImage.rejected.type, (state, action: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.result = null;
    });
  },
});

// Action creators
export const { resetUploads } = uploadSlice.actions;

export default uploadSlice.reducer;
