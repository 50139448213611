import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonRow, FormCheckBox, FormStepper, useTrackedFormik } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import { stepForward } from '../../../../store/enrolment';
import { useAppDispatch } from '../../../../store';
import { ENROLMENT_STEPS } from '../../../../core/constants/forms.constant';
import ROUTES from '../../../../core/constants/routes.constant';

function Eligibile() {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        eligibility1: false,
        eligibility2: false,
      },
      onSubmit: (values) => {
        dispatch(stepForward(values));

        if (formik.values.eligibility1 && formik.values.eligibility2) {
          navigateTo(ROUTES.enrolmentVideo);
        } else {
          navigateTo(ROUTES.enrolmentIneligible);
        }
      },
    },
    'enrol-eligibility',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={0} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Participant Eligibility
        </Typography>
        <Typography paragraph variant="body1" align="left">
          To take part in Discover Me South Africa, participants must be aged 18 years or over, and be a patient at
          Inkosi Albert Luthuli Central Hospital (IALCH).
        </Typography>
        <Typography paragraph variant="body1" align="left">
          Please confirm, preferably using the participant&apos;s appointment card, that they meet these eligibility
          criteria.
        </Typography>

        <Box mb={8}>
          <FormCheckBox
            error={formik.errors.eligibility1}
            name="eligibility1"
            label="I confirm that the participant is aged 18 years or over"
            onChange={formik.handleChange}
            touched={formik.touched.eligibility1}
            value={formik.values.eligibility1}
          />
          <FormCheckBox
            error={formik.errors.eligibility2}
            name="eligibility2"
            label="I confirm that the participant is a patient at IALCH"
            onChange={formik.handleChange}
            touched={formik.touched.eligibility2}
            value={formik.values.eligibility2}
          />
        </Box>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Eligibile;
