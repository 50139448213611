import { RootState } from '../index';
import { StoreStatus, KeyValues } from '../../core/types/common.types';

export const isBusySelector = (state: RootState): boolean => state.enrolment.status === StoreStatus.BUSY;

export const hasErrorSelector = (state: RootState): boolean =>
  state.enrolment.errors && state.enrolment.errors.length > 0 ? true : false;

export const errorMessagesSelector = (state: RootState): string[] =>
  state.enrolment.errors ? state.enrolment.errors.map((error) => error.message) : [];

export const firstErrorMessageSelector = (state: RootState): string | undefined =>
  state.enrolment.errors ? state.enrolment.errors.map((error) => error.message).pop() : undefined;

export const hasSuccessSelector = (state: RootState): boolean =>
  state.enrolment.result?.participant?.id ? true : false;

export const newParticipantIdSelector = (state: RootState): string | null =>
  state.enrolment.result?.participant?.id ? state.enrolment.result?.participant?.id : null;

export const formValueSelector = (state: RootState): KeyValues => state.enrolment.values || {};

export const getParticipantId = (state: RootState): string | null => state.enrolment.result?.participant?.id || null;
