import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { RiPhoneLockLine } from 'react-icons/ri';
import Typography from '@mui/material/Typography';
import QRCode from 'react-qr-code';
import { ButtonRow, FormInput, Loader, useTrackedFormik } from '@omnigenbiodata/ui';
import MainLayout from '../../../../layout/Main';
import { useAuth } from '@omnigenbiodata/ui';
import { MESSAGES } from '../../../../core/constants/forms.constant';
import { AUTH_CHALLENGES, MFA_ISSUER } from '../../../../core/constants/cognito.constant';

const validationSchema = yup.object().shape({
  otp: yup.string().required(MESSAGES.otpRequired),
});

function MultiFactor() {
  const { hasError, isBusy, challengeName, user, setupCode, completeSetupMfa, completeMfa } = useAuth();

  const formik = useTrackedFormik(
    {
      initialValues: { otp: '' },
      validationSchema,
      onSubmit: (values: any) => {
        const { otp } = values;
        if (challengeName === AUTH_CHALLENGES.MFA_SETUP) {
          completeSetupMfa(otp);
        } else {
          completeMfa(otp);
        }
      },
    },
    'auth-mfa',
  );

  return (
    <>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={8}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Multi Factor Authentication
            </Typography>
            <Typography paragraph variant="body1" align="center">
              Please enter your one time password below.
            </Typography>
          </Box>
          {hasError && (
            <Box mb={2}>
              <Alert severity="error">
                <Typography variant="body1" paragraph>
                  <strong>Invalid</strong>
                </Typography>
                <Typography variant="body1">The passcode you provided was incorrect.</Typography>
              </Alert>
            </Box>
          )}
          <Box mb={8}>
            <FormInput
              error={formik.errors.otp as string}
              name="otp"
              label="6-digit one time password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.otp as boolean}
              value={formik.values.otp}
              startAdornment={<RiPhoneLockLine />}
            />
          </Box>
          {setupCode && user && (
            <>
              <div style={{ textAlign: 'center', marginBottom: 90 }}>
                <QRCode
                  value={
                    `otpauth://totp/${MFA_ISSUER}:` + user.username + '?secret=' + setupCode + `&issuer=${MFA_ISSUER}`
                  }
                />
              </div>
              <span id="setupCode" style={{ opacity: 0 }}>
                {setupCode}
              </span>
            </>
          )}
          <ButtonRow forwardLabel="Confirm" forwardColor="primary" buttonSize="large" />
        </form>
      </MainLayout>
      <Loader isVisible={isBusy} label="Checking" />
    </>
  );
}

export default MultiFactor;
