import Typography from '@mui/material/Typography';
import InnerLayout from '../../layout/Inner';
import ParticipantInfo from '../../components/content/ParticipantInfo';
import LangauageToggle from '../../components/content/LangauageToggle';
import { STUDY_ADDRESS, STUDY_EMAIL, STUDY_TEL, STUDY_URL } from '../../core/constants/content.constant';

function Information() {
  return (
    <InnerLayout>
      <Typography variant="h4" component="h1" align="center" paragraph>
        Participant Information
      </Typography>
      <LangauageToggle />
      <ParticipantInfo address={STUDY_ADDRESS} email={STUDY_EMAIL} telephone={STUDY_TEL} web={STUDY_URL} />
    </InnerLayout>
  );
}

export default Information;
