import { useCallback, useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { ButtonRow, Loader, SummaryTable, useMatomo } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import { useAppSelector, useAppDispatch } from '../../../../store';
import {
  participantProfileSelector,
  hasParticipantSamples,
  isBusySelector,
} from '../../../../store/participant/selectors';
import { resetSample } from '../../../../store/samples';
import { PARTICIPANT_REVIEW_ROWS } from '../../../../core/constants/forms.constant';
import ROUTES from '../../../../core/constants/routes.constant';
import S3_CONFIG from '../../../../core/constants/s3.constant';

import NotFoundMessage from './components/NotFoundMessage';
import FoundMessage from './components/FoundMessage';
import ExistsMessage from './components/ExistsMessage';

function ParticipantResult() {
  const { ialchid } = useParams<any>();
  const dispatch = useAppDispatch();
  const participantProfile = useAppSelector(participantProfileSelector);
  const hasSamples = useAppSelector<boolean>(hasParticipantSamples);
  const isBusy = useAppSelector(isBusySelector);
  const navigateTo = useNavigate();
  const { trackEvent } = useMatomo();
  const [signedPDFUrl, setSignedPDFUrl] = useState<null | string>(null);
  const getSignedUrl = useCallback(async () => {
    const signedUrl = await Storage.get(`${ialchid}_dmsaconsent.pdf`, {
      bucket: S3_CONFIG.consentBucket,
    });
    setSignedPDFUrl(signedUrl);
  }, [ialchid]);

  useEffect(() => {
    dispatch(resetSample());
    if (participantProfile) {
      getSignedUrl();
    }
  }, [dispatch, getSignedUrl, participantProfile]);

  const handleHomeClick = () => {
    trackEvent({ category: 'samples', action: 'click return home' });
    dispatch(resetSample());
    navigateTo(ROUTES.root);
  };

  const handleEnrolmentClick = () => {
    trackEvent({ category: 'samples', action: 'click enrol participant' });
    navigateTo(ROUTES.enrolment);
  };
  const handleSearchAgainClick = () => {
    trackEvent({ category: 'samples', action: 'click search again' });
    navigateTo(ROUTES.samples);
  };

  const handleStartClick = () => {
    trackEvent({ category: 'samples', action: 'click start collection' });
    navigateTo(ROUTES.samplesSerum1);
  };

  const formValues: any = { ...participantProfile, ialchid };

  return (
    <>
      <InnerLayout>
        <Typography variant="h4" component="h1" align="center" paragraph>
          Participant Lookup
        </Typography>
        {isBusy && signedPDFUrl && <div data-testid="readyPdf"></div>}
        {!isBusy && !participantProfile && (
          <>
            <NotFoundMessage ialchid={ialchid} />{' '}
            <ButtonRow
              forwardLabel="Enrol New Participant"
              backLabel="Search Again"
              forwardColor="primary"
              buttonSize="large"
              onBack={handleSearchAgainClick}
              onForward={handleEnrolmentClick}
            />
          </>
        )}
        {!isBusy && participantProfile && (
          <>
            {!hasSamples && <FoundMessage />}
            {hasSamples && <ExistsMessage />}
            <Box mb={2}>
              <SummaryTable
                rows={PARTICIPANT_REVIEW_ROWS.map((row) => ({
                  ...row,
                  value: formValues[row.key],
                }))}
                label="Particpant Review"
              />
            </Box>
            <Box mb={8} textAlign="center">
              {signedPDFUrl && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  endIcon={<CloudDownloadIcon />}
                  href={signedPDFUrl}
                  target="_blank"
                  data-testid="downloadPdf"
                >
                  Download Consent Form
                </Button>
              )}
            </Box>
            {!hasSamples && (
              <ButtonRow
                forwardColor="primary"
                buttonSize="large"
                backLabel="Search again"
                forwardLabel="Start sample collection"
                onBack={handleSearchAgainClick}
                onForward={handleStartClick}
              />
            )}
            {hasSamples && (
              <ButtonRow
                backLabel="Return home"
                forwardColor="primary"
                buttonSize="large"
                showForward={false}
                onBack={handleHomeClick}
              />
            )}
          </>
        )}
      </InnerLayout>
      <Loader label="Searching" isVisible={isBusy} />
    </>
  );
}

export default ParticipantResult;
