import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import samplesApi from './samplesApi';
import { SampleState, SampleRejectionResponse, SampleSubmitResponse } from './types';
import { KeyValues, StoreStatus } from '../../core/types/common.types';

// Initial State
const initialState: SampleState = {
  errors: null,
  result: null,
  status: StoreStatus.IDLE,
  values: null,
};

// Thunks
export const submitSamples = createAsyncThunk<SampleSubmitResponse, KeyValues, SampleRejectionResponse>(
  'samples/submit',
  async (values, { rejectWithValue }) => {
    try {
      const response = await samplesApi.submitSamples(values);
      return response.data.studyParticipantUpdate;
    } catch (error: any) {
      return rejectWithValue(error.errors);
    }
  },
);

// Slice
export const sampleSlice = createSlice({
  name: 'samples',
  initialState,
  reducers: {
    resetSampleResult: (state) => ({
      ...state,
      status: initialState.status,
      error: initialState.errors,
      result: initialState.result,
    }),
    resetSample: () => ({
      ...initialState,
    }),
    stepForward: (state, action) => ({
      ...state,
      values: { ...state.values, ...action.payload },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(submitSamples.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });
    builder.addCase(submitSamples.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.result = action.payload;
      state.errors = null;
      state.values = null;
    });
    builder.addCase(submitSamples.rejected.type, (state, action: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = action.payload;
      state.result = null;
    });
  },
});

// Action creators
export const { resetSampleResult, stepForward, resetSample } = sampleSlice.actions;

export default sampleSlice.reducer;
