import ROUTES from './routes.constant';
import {
  BloodSampleType,
  Gender,
  PopulationGroup,
  LanguageName,
  LanguageCode,
  Department,
} from '../types/common.types';
import { SummaryItem, SelectOption } from '@omnigenbiodata/ui';

/* Validation Rules */
export const DATE_VALUE_FORMAT = 'YYYY-MM-DD HH:mm';
export const HOSPTIAL_NUM_PREFIX = 'KZ';
export const MOBILE_NUMBER_REGEX = /^(\+27|\+44)[0-9]{9,10}$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const POSTAL_CODE_REGEX = /^[0-9]{4}$/;
export const SAMPLE_BARCODE_LENGTH = 7;
export const SAMPLE_BARCODE_REGEX = /^[0-9]+$/;
export const TELEPHONE_NUMBER_REGEX = /^\+27[0-9]{9,10}$/;

/* Options / CodeLists */
export const BLOOD_STATUSES_VALID = [BloodSampleType.SUFFICIENT, BloodSampleType.INSUFFICIENT, BloodSampleType.NONE];
export const BLOOD_STATUSES_INVALID = [''];

export const BLOOD_STATUSES_ALL = [...BLOOD_STATUSES_VALID, ...BLOOD_STATUSES_INVALID].filter(
  (val: string) => val !== '',
);

export const DEPARTMENTS: Department[] = Object.values(Department);

export const DEPARTMENT_OPTIONS: SelectOption[] = DEPARTMENTS.map((department: Department) => ({
  label: department,
  value: department,
}));

export const LANGUAGE_OPTIONS: SelectOption[] = [
  { label: LanguageName.EN, value: LanguageCode.EN },
  { label: LanguageName.ZU, value: LanguageCode.ZU },
];

export const POPULATION_GROUP_OPTIONS: SelectOption[] = [
  { label: PopulationGroup.AFRICAN, value: PopulationGroup.AFRICAN },
  { label: PopulationGroup.COLOURED, value: PopulationGroup.COLOURED },
  { label: PopulationGroup.INDOASIAN, value: PopulationGroup.INDOASIAN },
  { label: PopulationGroup.WHITE, value: PopulationGroup.WHITE },
  { label: PopulationGroup.OTHER, value: PopulationGroup.OTHER },
];

export const SEX_OPTIONS: SelectOption[] = [
  { label: Gender.FEMALE, value: Gender.FEMALE },
  { label: Gender.INTERSEX, value: Gender.INTERSEX },
  { label: Gender.MALE, value: Gender.MALE },
];

/*  Steps */
export const ENROLMENT_STEPS = ['Information', 'Consent', 'Details', 'Review'];
export const SAMPLE_STEPS = ['Serum', 'Whole Blood', 'Plasma', 'Review', 'Signatures', 'Confirm'];
export const TOTAL_CONSENT_STEPS = 9;

/* Review Tables */
export const REVIEW_ROWS: SummaryItem[] = [
  {
    key: 'firstName',
    label: 'First name',
    format: 'titleCase',
    editPath: ROUTES.enrolmentConsent9,
  },
  {
    key: 'surname',
    label: 'Surname',
    format: 'titleCase',
    editPath: ROUTES.enrolmentConsent9,
  },
  {
    key: 'dob',
    label: 'Date of birth',
    format: 'friendlyDate',
    editPath: ROUTES.enrolmentDetails1,
  },
  {
    key: 'sex',
    label: 'Sex',
    format: 'titleCase',
    editPath: ROUTES.enrolmentDetails1,
  },
  {
    key: 'ialch',
    label: 'IALCH ID number',
    format: 'upperCase',
    editPath: ROUTES.enrolmentDetails1,
    prefix: HOSPTIAL_NUM_PREFIX,
  },
  {
    key: 'nationalID',
    label: 'National ID number',
    format: 'upperCase',
    editPath: ROUTES.enrolmentDetails1,
  },
  {
    key: 'populationGroup',
    label: 'Population group',
    format: 'titleCase',
    editPath: ROUTES.enrolmentDetails1,
  },
  {
    key: 'selfDefinedEthnicity',
    label: 'Ethnicity',
    format: 'titleCase',
    editPath: ROUTES.enrolmentDetails1,
  },
  {
    key: 'email',
    label: 'E-mail',
    format: 'lowerCase',
    editPath: ROUTES.enrolmentDetails2,
  },
  {
    key: 'mobile',
    label: 'Mobile',
    format: 'upperCase',
    editPath: ROUTES.enrolmentDetails2,
  },
  {
    key: 'landline',
    label: 'Landline',
    format: 'upperCase',
    editPath: ROUTES.enrolmentDetails2,
  },
  {
    key: 'postcode',
    label: 'Postal code',
    format: 'upperCase',
    editPath: ROUTES.enrolmentDetails2,
  },
  {
    key: 'address',
    label: 'First line of address',
    format: 'titleCase',
    editPath: ROUTES.enrolmentDetails2,
  },
  {
    key: 'witnessFirstName',
    label: 'Witness first name',
    format: 'titleCase',
    editPath: ROUTES.enrolmentConsent10,
  },
  {
    key: 'witnessSurname',
    label: 'Witness surname',
    format: 'titleCase',
    editPath: ROUTES.enrolmentConsent10,
  },
  {
    key: 'healthOptin',
    label: 'Receive your health information',
    format: 'titleCase',
    editPath: ROUTES.enrolmentConsent4,
  },
];

export const PARTICIPANT_REVIEW_ROWS: SummaryItem[] = [
  { label: 'IALCH ID number', key: 'ialchid', prefix: HOSPTIAL_NUM_PREFIX },
  { label: 'Participant Ref', key: 'participantRef' },
  {
    label: 'First name',
    key: 'firstName',
    format: 'titleCase',
  },
  {
    label: 'Surname',
    key: 'surname',
    format: 'titleCase',
  },
  {
    label: 'Date of birth',
    key: 'dob',
    format: 'friendlyDate',
  },
  {
    label: 'Sex',
    key: 'sex',
    format: 'titleCase',
  },
];

/* Messages */

export const MESSAGES = {
  addressIdentifierRequired: 'Postal Code or Address Line 1 required',
  barcodeFormat: 'Must be only digits',
  barcodeLength: `Must be exactly ${SAMPLE_BARCODE_LENGTH} digits`,
  barcodeMismatch: 'Blood sample barcodes must match',
  barcodeRequired: 'Please scan a barcode',
  collectionTimeRequired: 'Collection time is required',
  consentRequired: 'To continue with enrolment you must agree to this section',
  contactDetailsRequired: 'Email, Mobile or Landline required',
  departmentRequired: 'Department is required',
  dobRequired: 'D.O.B is Required',
  emailInvalid: 'Invalid E-mail address',
  emailRequired: 'E-mail is required',
  ethnicityRequired: 'Please provide a self-defined etnicity or select "Prefer not to say"',
  enrolmentError: '',
  fingerPrintorSigRequired: 'Fingerprint or Signature required',
  sigRequired: 'Signature required',
  firstNameRequired: 'First Name is required',
  idRequired: 'An IALCH identification number is required',
  hospitalNumRequired: 'IALCH ID Required',
  hospitalNumMismatch: "IALCH IDs don't match",
  hospitalNumConfirmRequired: 'Confirm IALCH ID is required',
  hospitalNumFormatError: 'IALCH ID must be digits only',
  hospitalNumLengthError: 'IALCH ID must be exactly 8 digits',
  hospitalNumExistsError: 'IALCH ID already enrolled',
  informationSheetRequired:
    "To continue with enrolment you must confirm the participant information sheet has been read or read out to the participant (and if required, their witness); the participant has been given the opportunity to ask questions and had their questions answered; and you have assessed the participant's capacity to consent and confirm they are able to make their own decisions to participate",
  landlineTelephoneFormat: 'Enter a valid landline number including country code (+27)',
  languageRequired: 'Language is required',
  lastNameRequired: 'Surname is required',
  mobileTelephoneFormat: 'Enter a valid mobile number including country code (+27)',
  nationalIDError: 'Invalid National ID format',
  otpRequired: 'One-time password is required',
  passwordConfirmRequired: 'Confirm new password is required',
  passwordRequired: 'Password is required',
  passwordFormat:
    'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
  passwordNoMatch: "Passwords don't match",
  pastDateRequired: 'Date/time must be in the past',
  plasmaStatusRequired: 'Please select a plasma blood status',
  populationGroupRequired: 'Population Group is Required',
  postalCodeFormat: 'Enter a valid Postal Code',
  prefsRequired: 'Please select whether you want to receive information from your health and care records',
  serumStatusRequired: 'Please select a serum status',
  sexRequired: 'Sex is Required',
  staffSigRequired: 'To continue with enrolment you must sign the consent form above',
  underMinAge: 'Participants must be aged 18 years or over',
  userFirstNameRequired: "It must be the same as the logged in user's first name",
  userLastNameRequired: "It must be the same as the logged in user's last name",
  participantFirstNameRequired: "It must be the same as the current participant's first name",
  participantLastNameRequired: "It must be the same as the current participant's last name",
  videoRequired: 'To continue with enrolment you must confirm the participant has viewed the enrolment video',
  wholeBloodStatusRequired: 'Please select a whole blood status',
  witnessSigRequired: 'To continue with enrolment the witness must sign the consent form above',
  witnessFirstNameRequired: "It must be the same as the current witness' first name",
  witnessLastNameRequired: "It must be the same as the current witness' last name",
};

const FORMS = {
  BLOOD_STATUSES_ALL,
  DATE_VALUE_FORMAT,
  HOSPTIAL_NUM_PREFIX,
  MOBILE_NUMBER_REGEX,
  PASSWORD_REGEX,
  ENROLMENT_STEPS,
  DEPARTMENTS,
  DEPARTMENT_OPTIONS,
  LANGUAGE_OPTIONS,
  POPULATION_GROUP_OPTIONS,
  SEX_OPTIONS,
  REVIEW_ROWS,
  SAMPLE_STEPS,
  MESSAGES,
};
export default FORMS;
