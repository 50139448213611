import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { ButtonRow, FormInput, FormStepper, useTrackedFormik } from '@omnigenbiodata/ui';
import SignatureBlock from '../../../../components/content/SignatureBlock';

import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import { ENROLMENT_STEPS, MESSAGES, TOTAL_CONSENT_STEPS } from '../../../../core/constants/forms.constant';
import { useAppDispatch } from '../../../../store';
import { stepForward } from '../../../../store/enrolment';

const validationSchema = yup.object({
  witnessFirstName: yup.string().required(MESSAGES.firstNameRequired),
  witnessSurname: yup.string().required(MESSAGES.lastNameRequired),
  witnessSignature: yup.string().required(MESSAGES.witnessSigRequired),
});

function Consent10() {
  const { t } = useTranslation('consent');
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        witnessFirstName: '',
        witnessSurname: '',
        witnessSignature: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.enrolmentConsent11);
      },
    },
    'enrol-witness-signature',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={1} />
        </Box>
        <Typography variant="h4" component="h1" align="center">
          9. {t('section9.title')}
        </Typography>
        <Typography variant="h5" component="h2" align="center" gutterBottom>
          {t('section9.witness.title')}
        </Typography>
        <MobileStepper
          backButton={false}
          nextButton={false}
          variant="dots"
          steps={TOTAL_CONSENT_STEPS}
          position="static"
          activeStep={8}
        />
        <Box mb={4}>
          <Typography variant="body1" component="p" paragraph>
            In instances where the participant must electronically sign the consent form with their fingerprint, for
            example because they cannot read the participant information sheet and consent form, a literate witness must
            also sign the consent form.
          </Typography>
          <Box mb={2}>
            <Paper elevation={5}>
              <Box p={3}>
                <Typography variant="body1" component="span">
                  <ReactMarkdown>{t('section9.witness.body')}</ReactMarkdown>
                </Typography>
              </Box>
            </Paper>
          </Box>
          <FormInput
            error={formik.errors.witnessFirstName}
            name="witnessFirstName"
            label={t('section9.witness.firstName')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.witnessFirstName}
            value={formik.values.witnessFirstName}
          />
          <FormInput
            error={formik.errors.witnessSurname}
            name="witnessSurname"
            label={t('section9.witness.surname')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.witnessSurname}
            value={formik.values.witnessSurname}
          />
        </Box>
        <Box mb={4}>
          <Grid container spacing={2}>
            <Grid item sm={2} />
            <Grid item sm={8}>
              <SignatureBlock
                error={formik.errors.witnessSignature}
                name="witnessSignature"
                label="Please ask the witness to sign the consent form"
                value={formik.values.witnessSignature}
                touched={formik.touched.witnessSignature}
                setValue={formik.setFieldValue}
              />
            </Grid>
          </Grid>
        </Box>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Consent10;
