import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { RiBarcodeFill } from 'react-icons/ri';
import { ButtonRow, FormInput, FormStepper, useTrackedFormik, Hidden } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import {
  MESSAGES,
  SAMPLE_BARCODE_LENGTH,
  SAMPLE_BARCODE_REGEX,
  SAMPLE_STEPS,
} from '../../../../core/constants/forms.constant';
import ROUTES from '../../../../core/constants/routes.constant';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { stepForward } from '../../../../store/samples';
import { formValueSelector } from '../../../../store/samples/selectors';

const validationSchema = yup.object({
  serumBloodBarcode: yup
    .string()
    .required(MESSAGES.barcodeRequired)
    .matches(SAMPLE_BARCODE_REGEX, MESSAGES.barcodeFormat)
    .min(SAMPLE_BARCODE_LENGTH, MESSAGES.barcodeLength)
    .max(SAMPLE_BARCODE_LENGTH, MESSAGES.barcodeLength),
});

function SerumScan() {
  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();
  const values = useAppSelector(formValueSelector);
  const formik = useTrackedFormik(
    {
      initialValues: {
        serumBloodBarcode: '',
        ...values,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.samplesSerum3);
      },
    },
    'samples-serum-scan',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={SAMPLE_STEPS} activeStep={0} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Serum Blood Sample Collection
        </Typography>

        <Typography paragraph variant="body1" align="center">
          Following standardised procedures collect the blood sample using the RED capped tube and scan the barcode
          using the attachable barcode scanner.
        </Typography>

        <Grid container spacing={2}>
          <Hidden smDown>
            <Grid item md={2} />
          </Hidden>
          <Grid item xs={12} md={8}>
            <Box mb={2}>
              <img
                src="/images/tubes/serum.png"
                alt=""
                style={{
                  margin: '0 auto',
                  display: 'block',
                  maxWidth: '100%',
                }}
              />
            </Box>
            <Box mb={8}>
              <FormInput
                autoFocus
                error={formik.errors.serumBloodBarcode}
                name="serumBloodBarcode"
                label="Serum Sample Barcode"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Scan barcode"
                touched={formik.touched.serumBloodBarcode}
                value={formik.values.serumBloodBarcode}
                startAdornment={<RiBarcodeFill fontSize="large" />}
              />
            </Box>
          </Grid>
        </Grid>

        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default SerumScan;
