import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import enrolementReducer from './enrolment';
import participantReducer from './participant';
import samplesReducer from './samples';
import uploadsReducer from './uploads';

const store = configureStore({
  reducer: {
    enrolment: enrolementReducer,
    participant: participantReducer,
    samples: samplesReducer,
    uploads: uploadsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
