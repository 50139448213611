import { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { ButtonRow, FormStepper, Loader, SummaryTable, useTrackedFormik } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import InnerLayout from '../../../../layout/Inner';

import { submitApplication } from '../../../../store/enrolment';
import {
  formValueSelector,
  hasErrorSelector,
  hasSuccessSelector,
  isBusySelector,
  firstErrorMessageSelector,
} from '../../../../store/enrolment/selectors';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { ENROLMENT_STEPS, REVIEW_ROWS } from '../../../../core/constants/forms.constant';
import { SUPPORT_EMAIL } from '../../../../core/constants/content.constant';
import { EnrolmentError } from '../../../../core/types/common.types';
import ROUTES from '../../../../core/constants/routes.constant';

function Review() {
  const { t } = useTranslation(['forms', 'values']);
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const formValues = useAppSelector(formValueSelector);
  const hasSubmissionError = useAppSelector(hasErrorSelector);
  const firstErrorMessage = useAppSelector(firstErrorMessageSelector);
  const hasSubmissionSuccess = useAppSelector(hasSuccessSelector);
  const isBusy = useAppSelector(isBusySelector);

  useEffect(() => {
    if (hasSubmissionSuccess) {
      navigateTo(ROUTES.enrolmentSuccess);
    }
  }, [hasSubmissionSuccess, navigateTo]);

  const formik = useTrackedFormik(
    {
      initialValues: { ...formValues },
      onSubmit: (values) => {
        dispatch(submitApplication(values));
      },
    },
    'enrol-review',
  );

  const translateValue = (key: string, value: any): string => {
    return value
      ? t([`${key}.${value.toString()}`, value], {
          ns: 'values',
        })
      : '';
  };

  const translateLabel = (key: string, label: any): string => {
    return t(key) !== key ? t(key) : label;
  };

  const translatedReviewRows = REVIEW_ROWS.map((row) => ({
    ...row,
    value: translateValue(row.key, formValues[row.key]),
    label: translateLabel(row.key, row.label),
  })).filter((row) => formValues[row.key]);

  return (
    <>
      <InnerLayout>
        <form onSubmit={formik.handleSubmit}>
          <Box my={3}>
            <FormStepper steps={ENROLMENT_STEPS} activeStep={3} />
          </Box>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            Review Enrolment Details
          </Typography>
          {hasSubmissionError && firstErrorMessage === EnrolmentError.EXISTS && (
            <Box mb={2}>
              <Alert severity="error">
                <Typography variant="body1" paragraph>
                  <strong>Already Enrolled</strong>
                </Typography>
                <Typography variant="body1">
                  This participant has already been enrolled in the Discover Me South Africa study. Please ensure the
                  IALCH ID number you have entered is correct. If you believe this is a valid IALCH ID number, and the
                  problem persists, contact <strong>{SUPPORT_EMAIL}</strong>
                </Typography>
              </Alert>
            </Box>
          )}

          {hasSubmissionError && firstErrorMessage === EnrolmentError.FINGERPRINT_SCORING_FAILED && (
            <Box mb={2}>
              <Alert severity="error">
                <Typography variant="body1" paragraph>
                  <strong>Fingerprint scoring failed</strong>
                </Typography>
                <Typography variant="body1">
                  The fingerprint submitted could not be processed. This may be due to a poor quality image. Please
                  ensure, when taking the image, there is sufficient light and the fingertips are clearly visible and in
                  focus. Please return to the participant signature step to retake the image. If the problem persists
                  contact <strong>{SUPPORT_EMAIL}</strong>
                </Typography>
              </Alert>
            </Box>
          )}

          {hasSubmissionError &&
            firstErrorMessage !== EnrolmentError.EXISTS &&
            firstErrorMessage !== EnrolmentError.FINGERPRINT_SCORING_FAILED && (
              <Box mb={2}>
                <Alert severity="error">
                  <Typography variant="body1" paragraph>
                    <strong>Enrolment Failed</strong>
                  </Typography>
                  <Typography variant="body1">
                    An unexpected error occurred. Please try again. If the problem persists contact{' '}
                    <strong>{SUPPORT_EMAIL}</strong>
                  </Typography>
                </Alert>
              </Box>
            )}
          <Box mb={8}>
            <SummaryTable rows={translatedReviewRows} />
          </Box>
          <ButtonRow forwardLabel="Complete Enrolment" isDisabled={isBusy} forwardColor="primary" buttonSize="large" />
        </form>
      </InnerLayout>
      <Loader label="Enroling Participant" isVisible={isBusy} />
    </>
  );
}

export default Review;
