import { useStyles } from './component.styles';
import { Loader } from '@omnigenbiodata/ui';

function Splash() {
  const { classes } = useStyles();

  return (
    <div data-testid="splash-wrapper" className={classes.splash}>
      <Loader isVisible />
    </div>
  );
}

export default Splash;
