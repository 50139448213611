import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RiSyringeLine } from 'react-icons/ri';
import { ButtonRow, FormStepper, Loader, SummaryTable } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import { SAMPLE_STEPS } from '../../../../core/constants/forms.constant';
import { useAppSelector, useAppDispatch } from '../../../../store';
import { stepForward } from '../../../../store/samples';
import { formValueSelector, isBusySelector } from '../../../../store/samples/selectors';
import { participantEnrolmentSelector } from '../../../../store/participant/selectors';
import { SignatureMethod } from '../../../../core/types/common.types';

function Review() {
  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();
  const values = useAppSelector(formValueSelector);
  const participantEnrolment = useAppSelector(participantEnrolmentSelector);
  const isBusy = useAppSelector(isBusySelector);

  const handleSubmit = () => {
    dispatch(stepForward(values));
    navigateTo(
      participantEnrolment?.signatureMethod === SignatureMethod.FINGERPRINT
        ? ROUTES.samplesWitnessSignature
        : ROUTES.samplesParticipantSignature,
    );
  };

  return (
    <>
      <InnerLayout>
        <Box my={3}>
          <FormStepper steps={SAMPLE_STEPS} activeStep={3} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Review Sample Collection
        </Typography>
        <Typography paragraph variant="body1" align="center">
          Please review the details below and ensure they are correct. Please amend if any errors are identified.
        </Typography>

        <Box mb={2} pl={2} style={{ borderLeft: '10px solid red' }}>
          <Typography variant="h6" component="h2" paragraph>
            <RiSyringeLine
              fontSize="inherit"
              style={{
                marginRight: 10,
                fontSize: '1.4em',
                verticalAlign: 'middle',
              }}
            />
            Serum Blood
          </Typography>
          <SummaryTable
            rows={[
              {
                key: 'serumBloodStatus',
                label: 'Status',
                value: values['serumBloodStatus'],
              },
              {
                key: 'serumBloodBarcode',
                label: 'Barcode',
                value: values['serumBloodBarcode'],
              },
              {
                key: 'serumBloodCollectionDateTime',
                label: 'Collection Time',
                format: 'friendlyDateTime',
                pattern: 'MMMM D, YYYY HH:mm',
                hideIfEmpty: true,
                value: values['serumBloodCollectionDateTime'],
              },
            ]}
            label="Serum Blood"
          />
        </Box>
        <Box mb={2} pl={2} style={{ borderLeft: '10px solid purple' }}>
          <Typography variant="h6" component="h2" paragraph>
            <RiSyringeLine
              fontSize="inherit"
              style={{
                marginRight: 10,
                fontSize: '1.4em',
                verticalAlign: 'middle',
              }}
            />
            Whole Blood
          </Typography>
          <SummaryTable
            rows={[
              {
                key: 'wholeBloodStatus',
                label: 'Status',
                value: values['wholeBloodStatus'],
              },
              {
                key: 'wholeBloodBarcode',
                label: 'Barcode',
                value: values['wholeBloodBarcode'],
              },
              {
                key: 'wholeBloodCollectionDateTime',
                label: 'Collection Time',
                format: 'friendlyDateTime',
                pattern: 'MMMM D, YYYY HH:mm',
                hideIfEmpty: true,
                value: values['wholeBloodCollectionDateTime'],
              },
            ]}
            label="Whole Blood"
          />
        </Box>
        <Box mb={8} pl={2} style={{ borderLeft: '10px solid purple' }}>
          <Typography variant="h6" component="h2" paragraph>
            <RiSyringeLine
              fontSize="inherit"
              style={{
                marginRight: 10,
                fontSize: '1.4em',
                verticalAlign: 'middle',
              }}
            />
            Plasma Blood
          </Typography>
          <SummaryTable
            rows={[
              {
                key: 'plasmaBloodStatus',
                label: 'Status',
                value: values['plasmaBloodStatus'],
              },
              {
                key: 'plasmaBloodBarcode',
                label: 'Barcode',
                value: values['plasmaBloodBarcode'],
              },
              {
                key: 'plasmaBloodCollectionDateTime',
                label: 'Collection Time',
                format: 'friendlyDateTime',
                pattern: 'MMMM D, YYYY HH:mm',
                hideIfEmpty: true,
                value: values['plasmaBloodCollectionDateTime'],
              },
            ]}
            label="Whole Blood"
          />
        </Box>
        <ButtonRow onForward={handleSubmit} forwardColor="primary" buttonSize="large" />
      </InnerLayout>
      <Loader label="Submitting Samples" isVisible={isBusy} />
    </>
  );
}

export default Review;
