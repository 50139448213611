import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth } from '@omnigenbiodata/ui';
import MultiFactor from './scenes/MultiFactor';
import NewPassword from './scenes/NewPassword';
import { AUTH_CHALLENGES } from '../../core/constants/cognito.constant';

function Auth() {
  const { challengeName, resetStatus } = useAuth();

  const [resetCount, setResetCount] = useState(0);

  useEffect(() => {
    if (resetCount < 1) {
      resetStatus();
      setResetCount(resetCount + 1);
    }
  }, [resetStatus, resetCount]);

  if (challengeName === AUTH_CHALLENGES.MFA_SETUP || challengeName === AUTH_CHALLENGES.SOFTWARE_TOKEN_MFA) {
    return <MultiFactor />;
  }

  if (challengeName === AUTH_CHALLENGES.NEW_PASSWORD || challengeName === AUTH_CHALLENGES.NEW_PASSWORD_REQUIRED) {
    return <NewPassword />;
  }

  return <Outlet />;
}

export default Auth;
