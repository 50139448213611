import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import moment from 'moment';
import { ButtonRow, FormStepper, FormDateTime, FormSelect, useTrackedFormik } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import {
  BLOOD_STATUSES_ALL,
  SAMPLE_STEPS,
  BLOOD_STATUSES_INVALID,
  BLOOD_STATUSES_VALID,
  DATE_VALUE_FORMAT,
  MESSAGES,
} from '../../../../core/constants/forms.constant';

import { useAppDispatch, useAppSelector } from '../../../../store';
import { stepForward } from '../../../../store/samples';
import { formValueSelector } from '../../../../store/samples/selectors';
import { BloodSampleType } from '../../../../core/types/common.types';

const validationSchema = yup.object({
  wholeBloodStatus: yup.string().required(MESSAGES.wholeBloodStatusRequired),
  wholeBloodCollectionDateTime: yup.string().when('wholeBloodStatus', {
    is: (value) => BLOOD_STATUSES_VALID.includes(value),
    then: yup
      .string()
      .test('isPast', MESSAGES.pastDateRequired, function (dateString) {
        return moment(dateString).isBefore();
      })
      .required(MESSAGES.collectionTimeRequired),
  }),
});

function WholeBloodStatus() {
  const dispatch = useAppDispatch();
  const values = useAppSelector(formValueSelector);
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        wholeBloodStatus: '',
        wholeBloodCollectionDateTime: moment().format(DATE_VALUE_FORMAT),
        ...values,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (BLOOD_STATUSES_INVALID.includes(values.wholeBloodStatus)) {
          values.wholeBloodCollectionDateTime = '';
        }
        dispatch(stepForward(values));
        navigateTo(ROUTES.samplesWholeBlood2);
      },
    },
    'samples-whole-status',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={SAMPLE_STEPS} activeStep={1} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Whole Blood Status
        </Typography>

        <Typography paragraph variant="body1" align="center">
          Please select the status of the sample taken
        </Typography>

        <Box mb={8}>
          <FormSelect
            error={formik.errors.wholeBloodStatus}
            name="wholeBloodStatus"
            label="Whole Blood Sample Status"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={BLOOD_STATUSES_ALL.map((item) => ({
              value: item,
              label: item,
            }))}
            placeholder=""
            touched={formik.touched.wholeBloodStatus}
            value={formik.values.wholeBloodStatus}
          />
          {BLOOD_STATUSES_VALID.includes(formik.values.wholeBloodStatus as BloodSampleType) && (
            <FormDateTime
              error={formik.errors.wholeBloodCollectionDateTime}
              name="wholeBloodCollectionDateTime"
              label="Collection Time"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              setValue={formik.setFieldValue}
              touched={formik.touched.wholeBloodCollectionDateTime}
              value={formik.values.wholeBloodCollectionDateTime}
            />
          )}
        </Box>

        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default WholeBloodStatus;
