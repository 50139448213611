import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { ButtonRow, FormInput, FormStepper, useTrackedFormik } from '@omnigenbiodata/ui';

import InnerLayout from '../../../../layout/Inner';
import ROUTES from '../../../../core/constants/routes.constant';
import {
  ENROLMENT_STEPS,
  MESSAGES,
  MOBILE_NUMBER_REGEX,
  TELEPHONE_NUMBER_REGEX,
  POSTAL_CODE_REGEX,
} from '../../../../core/constants/forms.constant';
import { stepForward } from '../../../../store/enrolment';
import { formValueSelector } from '../../../../store/enrolment/selectors';
import { useAppDispatch, useAppSelector } from '../../../../store';

const validationSchema = yup.object({
  email: yup
    .string()
    .email(MESSAGES.emailInvalid)
    .test('oneOfRequired', MESSAGES.contactDetailsRequired, function () {
      return this.parent.email || this.parent.mobile || this.parent.landline;
    }),
  mobile: yup
    .string()
    .matches(new RegExp(MOBILE_NUMBER_REGEX), MESSAGES.mobileTelephoneFormat)
    .test('oneOfRequired', MESSAGES.contactDetailsRequired, function () {
      return this.parent.email || this.parent.mobile || this.parent.landline;
    }),
  landline: yup
    .string()
    .matches(TELEPHONE_NUMBER_REGEX, MESSAGES.landlineTelephoneFormat)
    .test('oneOfRequired', MESSAGES.contactDetailsRequired, function () {
      return this.parent.email || this.parent.mobile || this.parent.landline;
    }),
  postcode: yup
    .string()
    .matches(POSTAL_CODE_REGEX, MESSAGES.postalCodeFormat)
    .test('oneOfRequired', MESSAGES.addressIdentifierRequired, function () {
      return this.parent.postcode || this.parent.address;
    }),
  address: yup.string().test('oneOfRequired', MESSAGES.addressIdentifierRequired, function () {
    return this.parent.postcode || this.parent.address;
  }),
});

function Details2() {
  const dispatch = useAppDispatch();
  const formValues = useAppSelector(formValueSelector);
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        email: '',
        mobile: '',
        landline: '',
        postcode: '',
        address: '',
        ...formValues,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.enrolmentReview);
      },
    },
    'enrol-details2',
  );

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit}>
        <Box my={3}>
          <FormStepper steps={ENROLMENT_STEPS} activeStep={2} />
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Participant Details 2
        </Typography>

        <Box mb={2}>
          <Paper variant="outlined">
            <Box p={2}>
              <Typography variant="h5" component="h2" gutterBottom>
                Contact Details
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Please enter at least one of the following:
              </Typography>

              <FormInput
                error={formik.errors.email}
                name="email"
                label="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.email}
                value={formik.values.email}
              />
              <FormInput
                error={formik.errors.mobile}
                name="mobile"
                label="Mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.mobile}
                value={formik.values.mobile}
              />
              <FormInput
                error={formik.errors.landline}
                name="landline"
                label="Landline"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.landline}
                value={formik.values.landline}
              />
            </Box>
          </Paper>
        </Box>
        <Box mb={8}>
          <Paper variant="outlined">
            <Box p={2}>
              <Typography variant="h5" component="h2" gutterBottom>
                Address Details
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Please enter at least one of the following:
              </Typography>

              <FormInput
                error={formik.errors.postcode}
                name="postcode"
                label="Postal code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.postcode}
                value={formik.values.postcode}
              />
              <FormInput
                error={formik.errors.address}
                name="address"
                label="First line of address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched.address}
                value={formik.values.address}
              />
            </Box>
          </Paper>
        </Box>
        <ButtonRow buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default Details2;
