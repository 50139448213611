import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

function FoundMessage() {
  return (
    <Box mb={2}>
      <Alert severity="success">
        <Typography variant="body1" gutterBottom>
          <strong>Participant Found</strong>
        </Typography>
        <Typography variant="body1">
          Confirm these details against the participant&apos;s appointment card before continuing.
        </Typography>
      </Alert>
    </Box>
  );
}

export default FoundMessage;
