import { useEffect } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import ROUTES from '../../core/constants/routes.constant';
import { formValueSelector, hasSuccessSelector } from '../../store/samples/selectors';
import {
  formValueSelector as participantFormValuesSelector,
  hasLookupSuccessSelector,
} from '../../store/participant/selectors';

import { useAppSelector } from '../../store';

function Samples() {
  const location = useLocation();
  const navigateTo = useNavigate();
  const sampleValues = useAppSelector(formValueSelector);
  const participantValues = useAppSelector(participantFormValuesSelector);
  const hasSuccess = useAppSelector(hasSuccessSelector);
  const hasLookupSuccess = useAppSelector(hasLookupSuccessSelector);

  useEffect(() => {
    if (
      location.pathname !== ROUTES.samples &&
      location.pathname !== ROUTES.samplesSuccess &&
      Object.values(sampleValues).length === 0 &&
      Object.values(participantValues).length === 0 &&
      !hasSuccess &&
      !hasLookupSuccess
    ) {
      navigateTo(ROUTES.samples); // redirect back to start if no questions answered
    }
  }, [navigateTo, location, sampleValues, participantValues, hasSuccess, hasLookupSuccess]);

  return <Outlet />;
}

export default Samples;
